import React from 'react'
import ContentLoader from 'react-content-loader'

const MyLoader = props => (
  <ContentLoader 
  width={1140}
  height={600} 
>
  <circle cx="110" cy="184" r="16" /> 
  <circle cx="170" cy="184" r="16" /> 
  <circle cx="231" cy="183" r="16" />
</ContentLoader>
)


export default MyLoader