import React, { Component } from 'react';
import FormSearch from './../FormSearch';

import './styles.css';

export default class SidebarForm extends Component {
    render(){
        return (            
            <div id="sidebar-form">
                <i className="fa fa-times-circle-o" onClick={this.props.close}/> 
                <FormSearch 
                    {...this.props}
                    close={this.props.close} 
                    buscar={this.props.buscar}
                    handleSelect={this.props.handleSelect}
                    handleSelectFilters={this.props.handleSelectFilters}
                    handleEnter={this.props.handleEnter}
                    handleOnChange={this.props.handleOnChange}
                    handleCheckedList={this.props.handleCheckedList}
                    handleCheckedListCondominio={this.props.handleCheckedListCondominio}
                />
            </div>       
        );
    }
}