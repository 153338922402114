import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

export default class Footer extends Component {
    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";
    
        document.body.appendChild(minscript);
    }

    render() {
        let phone = process.env.REACT_APP_PHONE;
        let now = new Date();

        return (
            <footer id="footer-main">           
                <div className="container">
                    <div className="column-left">
                        <div className="col">
                            <h2>Institucional</h2>
                            <ul>
                                <li><Link to="/sobre-nos">Sobre nós</Link></li>
                                <li><Link to={{pathname: process.env.REACT_APP_BLOG}} target="_blank">Blog</Link></li>
                                <li><a href="/aluguel/todos-os-tipos/todos-os-bairros-e-cidades">Imóveis para alugar</a></li>
                                <li><a href="/venda/todos-os-tipos/todos-os-bairros-e-cidades">Imóveis à venda</a></li>
                                <li><Link to="/politica-de-privacidade">Política de privacidade</Link></li>
                            </ul>
                        </div>
                        <div className="col">
                            <h2>Nossos Serviços</h2>
                            <ul>
                                <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target="_blank">2ª via de boleto</Link></li>
                                <li><Link to={{pathname: process.env.REACT_APP_LOGIN}} target="_blank">Prestação de contas</Link></li>
                                <li><Link to={{pathname: 'https://portal.seuma.fortaleza.ce.gov.br/fortalezaonline/portal/portallogado.jsf'}} target='_blank'>Consulta de adequabilidade</Link></li>
                                <li><Link to={{pathname: 'https://portal.seuma.fortaleza.ce.gov.br/fortalezaonline/portal/portallogado.jsf'}} target='_blank'>Alvará de funcionamento</Link></li>
                                <li><Link to={{pathname: process.env.REACT_APP_TICKET}} target="_blank">Abrir ticket</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="column-right">
                        <div className="funcionamento">
                            <div className="title">
                                <i className="fa fa-clock-o"></i> 
                                <p>Horário de Funcionamento</p>
                            </div>
                            <ul>
                                <li>Segunda à Sexta-Feira - 08:00h às 18:00h</li>
                                <li>Sábado - 08:00h às 12:00h</li>
                            </ul>
                            <div className="title">
                                <i className="fa fa-map-marker"></i>
                                <p>Nosso Endereço</p>
                            </div>
                            <ul>
                                <li> 
                                    {process.env.REACT_APP_ADDRESS} <br/> 
                                    {process.env.REACT_APP_CRECI}
                                </li>
                            </ul>
                        </div>
                        <div className="atendimento">
                            <div className="title">
                                <i className="fa fa-phone"></i>
                                <p>Central de Atendimento</p>
                            </div>
                            <div className="contato">
                                <sup>(85)</sup><h3>{phone.substring(3)}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="copy-right">
                    <div className="container">
                        <p>&copy; {now.getFullYear()} {process.env.REACT_APP_FULL_NAME} - Todos os direitos reservados</p>
                        <div className="redes-sociais">
                            <Link to={{pathname: process.env.REACT_APP_FACEBOOK}} target="_blank"><i className="fa fa-facebook"></i></Link>
                            <Link to={{pathname: process.env.REACT_APP_INSTAGRAM}} target="_blank"><i className="fa fa-instagram"></i></Link>
                            <Link to={{pathname: process.env.REACT_APP_BLOG}} target="_blank"><i className="fa fa-newspaper-o"></i></Link>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }   
}
