import React, { Component } from 'react';
import {Input} from "reactstrap";

import './../FormLocation/styles.css';

class SearchCode extends Component {
    state = {
        modalidade: 'aluguel',
        codigo:''
    };

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : '')
        });
    };

    handleOnChangeModalidade = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : ''),
            loading:true
        });
    };

    renderRedirect = (e) => {
        e.preventDefault();
        this.props.history.push(`/detalhes/${this.state.modalidade}/${this.state.codigo}`);
    };

    render() {
        return (                
            <form className="form-box" onSubmit={(e) => this.renderRedirect(e)}>
                <div className="busca">
                    <div className="select-box column-3">
                        <Input 
                            type="select" 
                            name="modalidade"                            
                            onChange={(e) => this.handleOnChangeModalidade(e)}
                            defaultValue={this.state.modalidade}>
                            <option value="aluguel">Aluguel</option>
                            <option value="venda">Comprar</option>
                        </Input>
                    </div>
                    <div className="input-box input-code">
                        <Input
                            type="text"
                            name="codigo"
                            onChange={(e) => this.handleOnChange(e)}
                            value={this.state.codigo} 
                            placeholder="Digite o código do imóvel" 
                        />
                    </div>
                    <div className="botao-box">
                        <button type="button" className="btn btn-primary" onClick={e => this.renderRedirect(e)}>Buscar</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default SearchCode;