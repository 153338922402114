import React from 'react';
import MapResult from './../MapResult';

import './styles.css';

const SidebarMap = ({close, imoveis}) => {
    return (            
        <div id="sidebar-map">
            <i className="fa fa-times-circle-o" onClick={close}/>
            <MapResult list={imoveis}/>
        </div>       
    );
}

export default SidebarMap;