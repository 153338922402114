import React from "react"
import ContentLoader from "react-content-loader"

const LoaderTitle = (props) => (
  <ContentLoader 
  height={25} width={330}
    {...props}
  >
    <circle cx="9" cy="9" r="9" /> 
    <circle cx="44" cy="9" r="9" /> 
    <circle cx="79" cy="9" r="9" />
  </ContentLoader>
)

export default LoaderTitle;