import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../../Menu';

import logomarca from '../../../../assets/logomarca.png';
import Form from './../../../../pages/Home/Header/Form';
import Favorites from '../../../../helpers/Favorites';
// import banner from '../../../../assets/Aviso-banner2.jpg';

import './styles.css';

export default class Navbar extends Component {
  constructor(props){
    super(props);
    this._favorites = new Favorites();
}
  render() {
    return (   
        <nav className={`navbar navbar-area navbar-expand-lg ${this.props.flag ? 'nav-header-home' : ''}`}>
          <div className="container">
            <div className="column-left">
              <div className="logomarca">
                <Link to="/"><img src={logomarca} alt="Torres de Melo Neto" /></Link>
              </div>
            </div>
            <div className="column-right">
              <ul className="content-nav">
                <li><a href="/aluguel/todos-os-tipos/todos-os-bairros-e-cidades">Alugar</a></li>
                <li><a href="/venda/todos-os-tipos/todos-os-bairros-e-cidades">Comprar</a></li>
              </ul>

              <ul className="content-buttons">
              <li id="favoritos">                
                  <Link to="/favoritos">
                    <i className="fa fa-heart" />
                    <span id='totalizadorFavorites' className="fav-count">{this._favorites.getQtdFavorites()}</span>
                    Favoritos
                  </Link>
                </li>

                <li>
                  <Link to='/comparativos-de-imoveis' >
                    <i className="fa fa-clone" />
                    Comparativos
                  </Link>
                </li>                            

                <li>
                  <Link to={{pathname: process.env.REACT_APP_LOGIN}} target="_blank" >
                    <i className="fa fa-lock" />
                    Login
                  </Link>
                </li>
              </ul>

              <Menu />
                        
            </div>
          </div>

          <div className="header-search">
            <div className="container">
              <Form {...this.props} />
            </div>
          </div>
        </nav>    
    );
  }
}