import React, { Component} from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Breadcrumbs from './Breadcrumbs';
import Title from './Title';
import ListFeatures from './ListFeatures';
import Services from './../Services';
import OwlCarousel from 'react-owl-carousel';
import Share from './Share';
import Swal from 'sweetalert2';
import Error404 from './Error404';
import MyLoader from './../MyLoader';

import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Leaflet from 'leaflet';
import LoadingMask from "react-loadingmask";
import 'leaflet.fullscreen/Control.FullScreen';

import './styles.css';

import dolar from '../../../assets/images/icons/dolar.svg';
import area from '../../../assets/images/icons/area.svg';
import quarto from '../../../assets/images/icons/quarto.svg';
import suite from '../../../assets/images/icons/suite.svg';
import banheiro from '../../../assets/images/icons/chuveiro.svg';
import vaga from '../../../assets/images/icons/vaga.svg';
import code from '../../../assets/images/icons/qr-code.svg';
import marker from '../../../assets/images/icons/marker.svg';
import Tour360 from '../../../components/globals/Cards/Label/Tour360';
import VideoLink from '../../../components/globals/Cards/Label/VideoLink';

const markerIcon = Leaflet.icon({
    iconUrl: marker,
    iconSize:[40, 40],
    iconAnchor:[20, 40],
    popupAnchor: [140, 18]
});

export default class Imovel extends Component {

    constructor(props){
        super(props);
        this.state = {
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
            loading: false,
            viewMap: false
        };

        this._services = new Services();
    }

    handleSubmitInteresseImovel = (event) => {
        event.preventDefault();
        this.setState({ loading: true })

        let dados =
        {
            "codigo": this.props.codigo,
            "modalidade": this.props.modalidade,
            "link": window.location.href,
            "titulo": this.props.imovel.tituloDetalhes,
            "nome": this.state.nome,
            "email": this.state.email,
            "telefone": this.state.telefone,
            "mensagem": this.state.mensagem
        }
        
        this._services.solicitarInformacoesImovel(dados).then(response => {
            this.setState({ loading: false }); 

            if (response.code === 1) {
                this.getAlert('success', 'Dados enviado com sucesso!');
                setTimeout( function() {
                    document.interesseImovelRD.submit();
                }, 1500);
            }
            if (response.code === 2) {
                this.getAlert('error', 'Ooops! Erro no envio dos dados.');
            }            
        });
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getAlert = (icon, message) => {
        return Swal.fire({
            position: 'center',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
        });
    }

    handleMap = () => {
        this.setState({ viewMap: true });
    }

    render() {
        let content = '';
        if(this.props.imovel.id) {            
            content = 
            <main id="detalhes-imovel">
                <div className="topo">                    
                    <div className="container">
                        <Breadcrumbs 
                            modalidade={this.props.finalidade}
                            tipo={this.props.imovel.tipo}
                            cidade={this.props.imovel.cidade}
                            bairro={this.props.imovel.bairro}
                            estado={this.props.imovel.estado}
                            titulo={this.props.imovel.tituloDetalhes}
                        />
                    </div>
                </div>
          
                <div className="container">
                    <div className="title">
                        <h1>{ this.props.imovel.tituloDetalhes }</h1>
                        <address>{ this.props.imovel.bairro }, { this.props.imovel.cidade } - { this.props.imovel.estado } </address>

                        <Link to={`/busca/${ this.props.finalidade }/${ this.props.imovel.tipo }/${this.props.imovel.bairro}`}>
                            <i className="fa fa-undo"/> Voltar para os resultados
                        </Link>
                    </div>
                    <div className="content">
                        <div className="col-left">
                            <div className="block block-details">

                                <div className="block-image">
                                    {this.props.imovel.destaque ?
                                        <span className='especial-label'><i className="fa fa-star" /> Destaque</span>
                                        : ''                            
                                    }                                    

                                    {this.props.imovel.tour !== '' ? <Tour360 tour={this.props.imovel.tour} /> : ''}
                                    {this.props.imovel.video !== '' ? <VideoLink video={this.props.imovel.video} /> : ''}
                                    
                                    <OwlCarousel
                                        className="owl-theme"
                                        responsive={this.props.responsive}
                                        nav
                                    >   
                                        { this.props.imovel.imagens.map(img => {
                                            return (
                                                <img key={img.id} src={img.imagem} alt={img.descricao} />
                                            );    
                                        }) }
                                    </OwlCarousel>
                                </div>
                
                                <ul className="especialFeatures">
                                    <li>
                                        <img src={dolar} alt="Preço"/>
                                        <span>Preço: { this.props.imovel.valor }</span>
                                    </li>
                                    <li>
                                        <img src={area} alt="Área"/>
                                        <span>área { this.props.imovel.area_util }m²</span>
                                    </li>
                                    <li>
                                        <img src={quarto} alt="Quartos"/>
                                        <span>{this.props.imovel.quartos} quarto{this.props.imovel.quartos > 1 ? 's' : ''}</span>
                                    </li>
                                    <li>
                                        <img src={suite} alt="Suítes"/>
                                        <span>{this.props.imovel.suites} suíte{this.props.imovel.suites > 1 ? 's' : ''}</span>
                                    </li>
                                    <li>
                                        <img src={banheiro} alt="Banheiros"/>
                                        <span>{this.props.imovel.banheiros} banheiro{this.props.imovel.banheiros > 1 ? 's' : ''}</span>
                                    </li>
                                    <li>
                                        <img src={vaga} alt="Vagas"/>
                                        <span>{this.props.imovel.vagas} vaga{this.props.imovel.vagas > 1 ? 's' : ''}</span>
                                    </li>
                                    <li>
                                        <img src={code} alt="Código"/>
                                        <span>Cód: { this.props.imovel.id }</span>
                                    </li>
                                </ul>                 

                                <div className="description">
                                    <Title text={this.props.imovel.tituloTextoDestaque} />
                                    <p>
                                        { this.props.imovel.descricao }
                                    </p>
                                </div>

                                <div className="list-features">
                                    <Title text='Características do imóvel' />                                
                                    <ListFeatures list={this.props.imovel.caracteristicas} />
                                </div>

                                
                                { this.props.imovel.condominio && this.props.imovel.condominio.length > 0 && 
                                    <div className="list-features">
                                        <Title text='Características do edifício' />                                
                                        <ListFeatures list={this.props.imovel.condominio} />
                                    </div>
                                }       
                            </div>
                                
                            <div className="block block-location">
                                <Title text='Localização do imóvel' />
                                {(this.props.imovel.nome_condominio ? `Condomínio: ${this.props.imovel.nome_condominio}` : '' )}

                                <address>{ this.props.imovel.endereco }, { this.props.imovel.bairro } - &nbsp;
                                { this.props.imovel.cidade } - {this.props.imovel.bairro}</address>

                                <div className="block-map">
                                    <div className="content">                                    
                                        <span onClick={()=>this.handleMap()}>Ver no mapa</span>
                                    </div>

                                    {this.state.viewMap ? 
                                        <Map 
                                        center={[this.props.imovel.latitude, this.props.imovel.longitude]}
                                        zoom={16}
                                        scrollWheelZoom={false}
                                        style={{ width: '100%', height: '100%' }}
                                        fullscreenControl={true}>

                                        <TileLayer
                                            url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                                        />

                                        <Marker
                                            position={[this.props.imovel.latitude, this.props.imovel.longitude]}
                                            icon={markerIcon}
                                        >
                                            <Popup closeButton={false} maxWidth={210} className="popup-map">
                                                { this.props.imovel.endereco }, { this.props.imovel.bairro } - &nbsp;
                                                { this.props.imovel.cidade } - {this.props.imovel.bairro}
                                            </Popup>
                                        </Marker>
                                    </Map>: ''}
              
                                </div> 
                            </div>
                        </div>
                        <div className="col-right">
                            <LoadingMask loading={this.state.loading}>
                            <div className="block block-form">

                                <h2>Saiba mais sobre este imóvel</h2> 
                            
                                <form onSubmit={event => this.handleSubmitInteresseImovel(event)} name='interesseImovelRD' >
                                    <div className="widget-sidebar-item-wrap rld-single-input">
                                        <Input
                                            name="nome"
                                            type="text"
                                            placeholder="Seu nome"
                                            required={true}
                                            onChange={event => this.handleOnChange(event)}
                                            value={this.state.nome}
                                        />
                                    </div>
                                    <div className="widget-sidebar-item-wrap rld-single-input">
                                        <Input
                                            name="email"
                                            type="email"
                                            placeholder="Seu e-mail"
                                            required={true}
                                            onChange={event => this.handleOnChange(event)}
                                            value={this.state.email}
                                        />
                                    </div>
                                    <div className="widget-sidebar-item-wrap rld-single-input">
                                        <MaskedInput
                                            name="telefone"
                                            mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            placeholder="Seu celular"                            
                                            guide={false}
                                            required={true}
                                            onChange={event => this.handleOnChange(event)}
                                            value={this.state.telefone}
                                        />
                                    </div>
                                    <div className="widget-sidebar-item-wrap rld-single-input">
                                        <Input
                                            name="mensagem"
                                            type="textarea"                                 
                                            defaultValue={`Olá, gostaria de receber mais informações sobre o imóvel no bairro ${this.props.imovel.bairro}, ${this.props.imovel.cidade} - ${this.props.imovel.estado.toUpperCase()}. O código do imóvel é ${this.props.imovel.id}. Aguardo retorno.`}
                                            required={true} 
                                            onChange={event => this.handleOnChange(event)}                                    
                                        />
                                    </div>                               
                                    <div className="btn-wrap">
                                        <button type="submit" className="btn btn-primary">Enviar dados</button>
                                    </div>                                
                                </form>

                            </div>
                            </LoadingMask>
                        
                            <Share/>

                        </div>                
                    </div> 

                </div>                
                <div className="content-alert">
                    <div className="container">                    
                        <p>
                            Observações: As informações dos imóveis exibidos neste site, podem ser alteradas a qualquer momento, inclusive quanto 
                            ao preço, sem aviso prévio. Os valores de condomínio e IPTU citados nesse site são apenas a título 
                            de referência e devem ser levantados no momento da visita. A área do imóvel e idade aqui informadas 
                            são aproximadas devendo ser confirmadas pelo cliente no momento da visita ao imóvel ou em sua respectiva 
                            documentação, assim como o número de vagas.
                        </p>
                    </div>
                </div>
            </main>            
        } else {            
            content = 
            <main id="detalhes-imovel">
                <Error404 codigo={this.props.codigo} />
            </main>
        }

        return (this.props.myloader ? <MyLoader /> : content);
    }
}