import React from 'react';

import './styles.css';

const ListFeatures = ({list}) => {
    return(
        <ul>
            {list.map((item, index) => (
                <li key={index}>
                    <i className="fa fa-check" /> &nbsp;
                    {item.nome}
                </li>
            ))}
            
        </ul>
    );
}

export default ListFeatures;