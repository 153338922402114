const listItems = [      
    {
        label: 'Segunda via de boleto',
        to: process.env.REACT_APP_LOGIN,
        target: '_blank',
        icon: 'fa fa-barcode'
    },    
    {
        label: 'Prestação de contas',
        to: process.env.REACT_APP_LOGIN,
        target: '_blank',
        icon: 'fa fa-calculator'
    },
    {
        label: 'Meus favoritos',
        to: '/favoritos',
        icon: 'fa fa-heart'
    },
    {
        label: 'Comparativos',
        to: '/comparativos-de-imoveis',
        icon: 'fa fa-clone'
    }
]

export default listItems;