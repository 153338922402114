import React from 'react';

import './styles.css';

const Card = (props) => {
    return (
        <div className="vantagens-card">
            <div className="wrapper">
                <div className="thumb">
                    <img src={props.icon} alt="Ícone card"/>
                </div>
                <h2>{props.children}</h2>
            </div>    
        </div>
    );
}
export default Card;