import React, { Component } from 'react';
import { Input, Button } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Swal from 'sweetalert2';
import LoadingMask from "react-loadingmask";
import Services from '../Services';

const tiposImoveis = [
    'Apartamento',
    'Flat',
    'Galpão',
    'Garagem',
    'Loja',
    'Ponto Comercial',
    'Quitinet',
    'Sala',
    'Sítio',
    'Terreno'
];

export default class FormPropertyRegistration extends Component {
    constructor(props){
        super(props);
        this._services = new Services(); 
        this.state = {
            nome: '',
            email: '',
            celular: '',
            telefone: '',
            bairro: '',
            tipo: 'Apartamento',
            modalidade: 'aluguel',
            loading: false
        }
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    clearInputs = () => {        
        this.setState({
            nome: '',
            email: '',
            celular: '',
            telefone: '',
            bairro: '',
            tipo: 'Apartamento',
            modalidade: 'aluguel'
        });        
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({loading: true});

        let dados = 
        {   
            "nome": this.state.nome,
            "email": this.state.email,
            "celular": this.state.celular,
            "telefone": this.state.telefone,
            "bairro": this.state.bairro,
            "tipo": this.state.tipo,
            "modalidade": this.state.modalidade
        }

        this._services.cadastrarImovel(dados)
            .then(response => {
                this.setState({loading: false});

                if (response.code === 1) {
                    this.getAlert('success', 'Dados enviado com sucesso!');
                    this.clearInputs();                    
                }
                if (response.code === 2) {
                    this.getAlert('error', 'Ooops! Erro no envio dos dados.');
                } 
            });
    }

    getAlert = (icon, message) => {
        return Swal.fire({
            position: 'center',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
        });
    }

    render() {
        return (
            <LoadingMask loading={this.state.loading}>
            <div id="block-form-registration" className="block-form">
                <h2>Preencha o formulário e deixe que a gente faça o trabalho pesado por você</h2>
                <form onSubmit={(event) => this.handleClick(event)}>
                    <div className="row">
                        <div className="widget-sidebar-item-wrap rld-single-select col-md-6">
                            <Input
                                className="select single-select"
                                type="select"
                                name="modalidade"
                                onChange={(event)=>this.handleOnChange(event)}
                                value={this.state.modalidade}
                            >
                                <option value='Alughel'>aluguel</option>
                                <option value='Venda'>Venda</option>
                                <option value='aluguel e venda'>aluguel e venda</option>
                            </Input>
                        </div>
                        <div className="widget-sidebar-item-wrap rld-single-select col-md-6">
                            <Input
                                type="select"
                                name="tipo"
                                className="select single-select"
                                onChange={(event)=>this.handleOnChange(event)}
                                value={this.state.tipo}
                            >
                                {tiposImoveis.map((tipo, index) => (
                                    <option key={index} value={tipo}>{tipo}</option>
                                ))}
                            </Input>
                        </div>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-input col-md-12">
                        <Input
                            name="bairro"
                            type="text"
                            placeholder="Bairro e Cidade - Ex: Benfica - Fortaleza - CE"
                            required={true}
                            onChange={(event)=>this.handleOnChange(event)}
                            value={this.state.bairro}
                        />
                    </div>

                    <div className="widget-sidebar-item-wrap rld-single-input col-md-12">
                        <Input 
                            name="nome"
                            type="text"
                            placeholder="Seu nome"
                            required={true}
                            onChange={(event)=>this.handleOnChange(event)}
                            value={this.state.nome}
                        />
                    </div>

                    <div className="widget-sidebar-item-wrap rld-single-input col-md-12">
                        <Input
                            name="email"
                            type="email"
                            placeholder="Seu e-mail"
                            required={true}
                            onChange={(event)=>this.handleOnChange(event)}
                            value={this.state.email}
                        />
                    </div>

                    <div className="row">
                        <div className="widget-sidebar-item-wrap rld-single-input col-md-6">
                            <MaskedInput
                                name='telefone'
                                mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Seu telefone"                            
                                guide={false}
                                onChange={(event)=>this.handleOnChange(event)}
                                value={this.state.telefone}
                            />
                        </div>
                        <div className="widget-sidebar-item-wrap rld-single-input col-md-6">
                            <MaskedInput
                                name='celular'
                                mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Seu celular"                            
                                guide={false}
                                required={true}
                                onChange={(event)=>this.handleOnChange(event)}
                                value={this.state.celular}
                            />
                        </div>                             
                    </div>
                    <div className="btn-wrap col-md-6">
                        <Button type="submit" className="btn">Cadastrar meu imóvel</Button>
                    </div>                                
                </form>
            </div>
            </LoadingMask>
        );
    }
}