import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Headline from '../../../components/globals/HeadLine';
import Card from "../../../components/globals/Cards/Vantagens";

import imgEquipe from './../../../assets/images/equipe-empresa.jpg';
import diretorPresidente from './../../../assets/images/diretor-presidente.png';
import diretoraFinanceira from './../../../assets/images/diretora-financeira.png';
import diretoraJuridica from './../../../assets/images/diretora-juridica.png';
import diretorLocacao1 from './../../../assets/images/diretor-locacao1.png';
import diretorLocacao2 from './../../../assets/images/diretor-locacao2.png';

import etica from '../../../assets/images/icons/etica.svg';
import transparencia from '../../../assets/images/icons/transparencia.svg';
import proAtivo from '../../../assets/images/icons/pro-ativo.svg';

import './styles.css';

export default class Content extends Component {    
    render() {
        return (
            <main id='about-as-main'>
                <div className="topo">                    
                    <div className="container">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to='/'>Torres de Melo Neto</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Sobre nós
                        </BreadcrumbItem>
                    </Breadcrumb>
                    </div>
                </div>

                <div className="container">
                    <div className="block-about-us">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mb-4 mb-lg-0">
                                <div className="shape-image-list-wrap">
                                    <div className="shape-image-list left-top">
                                        <img className="shadow-img" src={ imgEquipe } alt="Equipe Torres de Melo Neto" />
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="section-title pd-left mb-0">
                                        <h5 className="sub-title"> Sobre nós </h5>
                                        <h1 className="title">Breve história de nossa empresa</h1>
                                        <p>
                                            A Imobiliária Torres de Melo Neto LTDA foi fundada em 10/11/1988. É uma empresa 
                                            familiar que já se encontra na terceira geração e traz em sua essência a ética e a 
                                            credibilidade da família Torres de Melo mesclada com o profissionalismo e dinamismo 
                                            empresarial que a fez conquistar, rapidamente, um lugar de destaque no mercado imobiliário 
                                            cearense. 
                                        </p>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className="block-mission">
                    <div className="container">
                        <Headline label="Nossa missão" />
                        <p>
                            A completa satisfação do cliente em sua demanda pela compra e venda e administração de imóveis 
                            através de um atendimento eficaz e personalizado que transmita ao cliente toda a confiabilidade e 
                            segurança de estar realizando um negócio em parceria com a Imobiliária Torres de Melo Neto LTDA.
                        </p>
                    </div>
                </div>

                <div className="block-ethic">
                    <div className="container">
                        <Headline label="Áreas de atuação e nossos valores" />
                        <p>A empresa atua nas áreas de Venda, Locação e consultoria imobiliária em toda Fortaleza/CE e Região Metropolitana.</p>

                        <div className="wrapper-cards">
                            <Card icon={etica}>
                                <span>Ética</span>
                            </Card>
                            <Card icon={transparencia}>
                                <span>Transparência</span>
                            </Card>
                            <Card icon={proAtivo}>
                                <span>Gestão pró-ativa</span>
                            </Card>
                        </div>                        
                    </div>
                </div>
                
                <div className="block-team">
                    <Headline label="Nosso time de Diretores" />

                    <div className="container">
                        <div className="single-team">
                            <div className="thumb">
                                <img src={diretorPresidente} alt="Diretor Presidente" />
                            </div>
                            <div className="team-details">
                                <h4>Torres de Melo Neto</h4>
                                <span>Diretor Presidente</span>
                            </div>
                        </div>                       
                        <div className="single-team">
                            <div className="thumb">
                                <img src={diretoraFinanceira} alt="Diretor Financeira" />
                            </div>
                            <div className="team-details">
                                <h4>Vânia Figueiredo Torres de Melo</h4>
                                <span>Diretora Financeira</span>
                            </div>
                        </div>
                        <div className="single-team">
                            <div className="thumb">
                                <img src={diretoraJuridica} alt="Diretora Jurídica" />
                            </div>
                            <div className="team-details">
                                <h4>Rebeca Werton Torres de Melo</h4>
                                <span>Diretora Jurídica</span>
                                <span>OAB/CE 35203</span>
                            </div>
                        </div>                      
                        <div className="single-team">
                            <div className="thumb">
                                <img src={diretorLocacao2} alt="Diretor de Locação" />
                            </div>
                            <div className="team-details">
                                <h4>Miguel Tomaz Ramos Leite</h4>
                                <span>Diretor de Locação</span>
                                <span>OAB/CE 13035</span>
                            </div>
                        </div>                     
                        <div className="single-team">
                            <div className="thumb">
                                <img src={diretorLocacao1} alt="Diretor de Locação" />
                            </div>
                            <div className="team-details">
                                <h4>Luciano Werton Torres de Melo</h4>
                                <span>Diretor de Locação</span>
                                <span>CREA/CE 340501</span>
                            </div>
                        </div>                        
                    </div>                
                </div>                
            </main>
        );
    }        
}
