import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Services from './Services';

export default class Posts extends Component {
    constructor(props) {
        super(props);
        this._services = new Services();

        this.state = {
            posts: []
        }
    }

    componentDidMount() {
        this._services.getPosts()
            .then(apiposts => {
                this.setState({
                    posts: apiposts
                });
            });        
    }

    render() {
        const listPost = this.state.posts.filter((post, index, array) => array.indexOf(post) < 3);   
              
        return (
            <Fragment>
                {listPost.map((post, index) => (
                    <article key={index}>       
                        <img src={post.yoast_head_json.og_image[0].url.replace('http:', 'https:')} alt={post.title.rendered}/>                    
                        <div className="wrapper">
                            <Link to={{ pathname: post.link }} rel="noopener noreferrer" target="_blank">
                                <h2>{post.title.rendered}</h2>
                            </Link>

                            <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered.replace('Read More', '')}} />

                            <Link to={{ pathname: post.link }} rel="noopener noreferrer" target="_blank">Continuar lendo</Link>
                        </div>
                    </article>
                ))}
            </Fragment>
        );
    }
}