import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import Content from './Content';

export default class Buyers extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    
    render() {
        return (
            <Fragment>
                <Helmet title={`Compre seu imóvels | ${process.env.REACT_APP_FULL_NAME}`} />
                <Header />
                <Content />
                <Footer />
            </Fragment>
        );
    }
}