/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';

import Utils from '../../../../../helpers/Utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: 'relative'
  },
  button: {
    fontSize: '1rem',
    width: '100%',
    height: '48px',
    background: theme.palette.common.white,
    border: '1px solid #ced4da',
    borderRadius: 5,    
    padding: '.375rem .75rem;',
    color: '#495057',
    justifyContent: 'flex-start'
  },
  popper: {
    marginTop: '-48px',
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 320,     
    zIndex: 100000,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
    '& span': {
      position: 'fixed',
      zIndex: 10000,
      left: 0,
      bottom: 0,
      width: '100%',
      background : '#f8f9fa',
      cursor: 'pointer',
      padding: 8,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',      
      '& button': {
        color: '#586069',
        background : '#fff',
        fontSize: 14,
        cursor: 'pointer',
        padding: '4px 8px',
        borderRadius: 5,
        textAlign: 'center',
        margin: 0,
        border: '1px solid #586069'
      },
    },    
  },
  inputBase: {
    padding: 10,
    width: '100%',
    background: theme.palette.common.white,
    '& input': {
      borderRadius: 5,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {        
        borderColor: '#ed3237',
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
    marginBottom: 48
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 10,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: 'relative',
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
    color: 'green',
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}));

export default function SelectOptions(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [label, setLabel] = React.useState([]);
  const [value, setValue] = React.useState([]);
  const [pendingValue, setPendingValue] = React.useState([]);  

  const handleClick = (event) => {      
    setPendingValue(value);
    setAnchorEl(event.currentTarget);    
  };

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);  
    setLabel(pendingValue.map(item => (item.name)));
    props.getOptions(pendingValue.map(item => (item.value)));   
  };

  const clear = () => {
    setPendingValue([]);
  }

  const handleTemp = (event, newValue, valueTodos) => {  
    let item = [];
    let data = [];
    
    data = newValue.filter(function( element ) {
      return element !== undefined;
    });

    if (event.target.id === valueTodos) {
      item = data.filter((item) => {
        return item.value === valueTodos;
      });
    } else {
      item = data.filter((item) => {
        return item.value !== valueTodos;
      });
    }

    setPendingValue(item);
  }

  const open = Boolean(anchorEl);
  const id = open ? `select${props.name}` : undefined;  

  useEffect(() => {
    const dados = props.options;
    const listOptions = [];

    props.selecteds.map(option => {
      let i = dados.indexOf(dados.filter((item) =>  item.value === option)[0],0);
      return listOptions.push(props.options[i]);
    });
    setValue(listOptions);
    setLabel(props.selecteds.map(item => (Utils.capitalize(item.replace(/-/g, ' ')))));
  },[props.options, props.selecteds])
 
  return ( 
    <div className={classes.root}>
        <ButtonBase
          disableRipple
          className={classes.button}
          aria-describedby={id}
          onClick={handleClick}
        >
          { label.length === 0 ? props.name :
            label.length > 1 ? `${label[0]} +${label.length - 1}` : label}
          
        </ButtonBase>
      
        <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            className={classes.popper}
        >
            <Autocomplete
                open
                onClose={handleClose}
                multiple
                classes={{
                    paper: classes.paper,
                    option: classes.option,
                    popperDisablePortal: classes.popperDisablePortal,
                }}
                value={pendingValue}
                onChange={(event, newValue) => {
                  handleTemp(event, newValue, props.valueTodos)
                }}
                disableCloseOnSelect
                disablePortal
                renderTags={() => null}
                noOptionsText="Sem resultado"
                groupBy={(option) => option.category}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                    <DoneIcon                    
                        className={classes.iconSelected}
                        style={{ visibility: selected ? 'visible' : 'hidden' }}
                    />              
                    <div id={option.value} className={classes.text}>
                        {option.name}
                    </div>
                    <CloseIcon
                        className={classes.close}
                        style={{ visibility: selected ? 'visible' : 'hidden' }}
                    />
                    </React.Fragment>
                )}
                options={[...props.options].sort((a, b) => {
                    // Display the selected labels first.
                    let ai = value.indexOf(a);
                    ai = ai === -1 && a.category === b.category ? value.length + props.options.indexOf(a) : ai;
                    let bi = value.indexOf(b);
                    bi = bi === -1 && b.category === a.category ? value.length + props.options.indexOf(b) : bi;
                    return ai - bi;
                })}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <React.Fragment>
                    <InputBase
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    autoFocus
                    className={classes.inputBase}
                    />
                    <span>
                        <button 
                            type='button'
                            onClick={clear}>
                            Limpar
                        </button>                        
                    </span>
                  </React.Fragment>
                )}
            />
        </Popper>
    </div>
  );
}