import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import Topo from './Topo';
import Content from './Content';

import Services from "./Services";
import ServicesBusca from "./../Home/Services";
import fields from "./fields";
import Util from '../../helpers/Utils';

export default class SearchResult extends Component {
    state= {
        ...fields,
        intervalId: 0
    };

    constructor(props) {
        super(props);
        this._services = new Services();
        this._servicesBusca = new ServicesBusca();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let params = (nextProps.match.params.filtros ? nextProps.match.params.filtros : '');
        let params_filtros = {};

        if (params !== '') {
            let filtros = '';
            let padrao = [];
            filtros = params.split('+');

            for (let i in filtros) {                
                padrao = filtros[i].split('-');
                params_filtros[padrao[1]] = padrao[0];                
            }
        }

        if (
            nextProps.match.params.modalidade === prevState.modalidade ||
            nextProps.match.params.tipo === prevState.tipo ||
            nextProps.match.params.endereco === prevState.endereco ) {
            return null;
        }        
        
        return {
            modalidade: nextProps.match.params.modalidade,
            listTipos: nextProps.match.params.tipo.split('+'),
            listEnderecos: nextProps.match.params.endereco.split('+'),
            filtros: params_filtros          
        };
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        this._services.busca(
            this.state.modalidade,
            this.state.listTipos,
            this.state.listEnderecos,
            this.state.filtros,
            this.state.page,
            this.state.order,
            this.state.limit,
            this.state.offset,
            this.state.caracSelecionados,
            this.state.caracSelecionadosCondominio
        )
            .then(response => {
                this.setState({
                    imoveis: (response.hasOwnProperty('resultado') ? response.resultado.imoveis : []),
                    total: (response.hasOwnProperty('resultado') ? response.resultado.total : 0),
                    loadingContent: false,               
                });
            })
            .catch(e => console.log(e));
        
        this._services.getCaracteristicasImovel(this.state.modalidade, this.state.listTipos)
            .then(caracteristicas => this.setState({caracteristicas: caracteristicas.options ? caracteristicas.options : []}));    
        
        this._services.getCaracteristicasCondominio(this.state.modalidade, this.state.listTipos)
            .then(caracsCondominio => this.setState({caracsCondominio: caracsCondominio.options ? caracsCondominio.options : []}));

        this._servicesBusca.getTiposImoveisOptions(this.state.modalidade)
            .then(tipos => this.setState({tipos: tipos.options}));

        this._servicesBusca.getbuscaEndereco(this.state.modalidade,'')
            .then(endereco => this.setState({
                bairros: endereco.resultado.bairros,
                cidades: endereco.resultado.cidades                
            }));
    }

    buscar = () => {
        this.scrollToTop()      
        this.setState({loadingContent:true, imoveis: []});
        this._services.busca(
            this.state.modalidade, 
            this.state.listTipos, 
            this.state.listEnderecos, 
            this.state.filtros, 
            this.state.page, 
            this.state.order, 
            this.state.limit, 
            this.state.offset, 
            this.state.caracSelecionados, 
            this.state.caracSelecionadosCondominio)
            .then(response => {
                let tipos = this.state.listTipos.toString();
                let endereco = this.state.listEnderecos.toString();
     
                this.props.history.push(
                    '/'+this.state.modalidade.toLowerCase()+
                    '/'+Util.transliterate(tipos.replace(/,/g,'+'))+
                    '/'+endereco.replace(/,/g,'+')
                    +this.adicionarFiltros()
                );

                this.setState({
                    imoveis: (response.hasOwnProperty('resultado') ? response.resultado.imoveis : []),
                    total: (response.hasOwnProperty('resultado') ? response.resultado.total : 0),                 
                    loadingContent: false
                });
            })
            .catch(e => console.log(e));

        this._services.getCaracteristicasImovel(this.state.modalidade, this.state.listTipos)
            .then(caracteristicas => this.setState({caracteristicas: caracteristicas.options}));
        
        this._services.getCaracteristicasCondominio(this.state.modalidade, this.state.listTipos)
            .then(caracsCondominio => this.setState({caracsCondominio: caracsCondominio.options}));    
    };
    
    handleSelect = (e) => {         
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : ''),
            offset: 0,
            caracSelecionados:[],
            caracSelecionadosCondominio: []
        }, () => {
            this.buscar();
        });
    };

    handleSelectFilters = (e) => {           
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value : '')
            },
            offset: 0
        }, () => this.buscar());
    };

    handleOnChange = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value.replace('.','').replace('.','').replace(',00','') : '')
            }
        });
    };

    handleEnter = (e) => {
        if (e.key === 'Enter') {
            this.setState({              
                offset: 0
            }, () => {
                this.buscar();
            });
        }
    };

    handleCheckedList = (e) => {
        let list = this.state.caracSelecionados;
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        )
        this.setState({
            caracSelecionados: list,
            offset: 0
        }, () =>this.buscar())
    }

    handleCheckedListCondominio = (e) => {
        let list = this.state.caracSelecionadosCondominio;        
        (
            e.target.checked ? 
            list.push({name: e.target.name , value: e.target.value }) : 
            list.splice(list.findIndex(index => index.value === e.target.value), 1)
        )
        this.setState({
            caracSelecionadosCondominio: list,
            offset: 0
        }, () =>this.buscar())
    }

    handleOnChangeOrder = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value : '')
            }
        }, () => {
            this.buscar();
        });
    };

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 12);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), 5);
        this.setState({ intervalId: intervalId });
    }

    adicionarFiltros() {        
        let filtros = this.state.filtros;
        let resultado = "";
        
        for (let i in filtros) {
            if (filtros[i] !== ''){
                if (i !== 'order'){ resultado += filtros[i] + '-' + i + '+';}
            }
        }
        return '/'+ resultado.substr(0,(resultado.length - 1));
    }

    handlePaginacao = (name, value) => {
        this.setState({
            [name]: value
        }, () => {
            this.buscar();
        });
    };

    handlePageChange = (pageNumber) => {    
        this.setState({page: pageNumber});
        this.handlePaginacao('offset', ((this.state.limit * pageNumber) - this.state.limit))
    }

    getTipos = (list) => {        
        this.setState({
            listTipos: list.length > 0 && list.indexOf('todos-os-tipos') === -1 ?
            list : ['todos-os-tipos']
        }, () => this.buscar());
    }

    getEnderecos = (list) => {
        this.setState({
            listEnderecos: list.length > 0 && list.indexOf('todos-os-bairros-e-cidades') === -1 ? 
            list : ['todos-os-bairros-e-cidades']
        }, () => this.buscar());
    }

    render() {
        return (
            <Fragment>
                <Helmet 
                title={
                    `Imoveis ${this.state.modalidade === 'aluguel' ? 'para alugar': 'à venda'} | 
                    ${process.env.REACT_APP_FULL_NAME}`} 
                />

                <Header />
                <main>
                    <Topo
                        {...this.state}
                        handleOnChangeOrder={this.handleOnChangeOrder}
                    />
                    <Content 
                        {...this.state}
                        buscar={this.buscar}
                        handleSelect={this.handleSelect}
                        handleSelectFilters={this.handleSelectFilters}
                        handleEnter={this.handleEnter}
                        handleOnChange={this.handleOnChange}
                        handleCheckedList={this.handleCheckedList}
                        handleCheckedListCondominio={this.handleCheckedListCondominio}
                        adicionarFiltros={this.adicionarFiltros()}
                        handlePaginacao={this.handlePaginacao}
                        handlePageChange={this.handlePageChange}
                        getTipos={this.getTipos}
                        getEnderecos={this.getEnderecos}
                        
                    />
                </main>
                <Footer />
            </Fragment>
        );
    }        
}