import React from 'react';
import Routes from './routes';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'react-select2-wrapper/css/select2.css';
import "react-loadingmask/dist/react-loadingmask.css";

import './assets/css/styles.css';

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;