import React, { Fragment } from 'react';
import Card from "../../../components/globals/Cards/Vantagens";
import Botao from "../../../components/globals/Botao";

import consultor from '../../../assets/images/icons/consultor.svg'
import seguranca from '../../../assets/images/icons/seguranca.svg'
import contrato from '../../../assets/images/icons/contrato.svg'

const Corretores = () => {
    return (
        <Fragment>       
            <Card icon={consultor}>
                Consultor imobiliário: Temos uma equipe de corretores e gerentes experientes para te auxiliar a fazer o melhor negócio
            </Card>
            <Card icon={seguranca}>
                Segurança jurídica: Elaboramos um contrato com todo aparato jurídico para garantir maior segurança na intermediação
            </Card>
            <Card icon={contrato}>
                Oportunidades de negócio: Buscamos as melhores opções disponíveis para o seu perfil
            </Card>

            <Botao to='/compradores' text="Realizar meu cadastro" />
           
        </Fragment>
    );
}

export default Corretores;