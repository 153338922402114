import React from 'react';
import Botao from '../../../components/globals/Botao';

import './styles.css';

const NoFavorites = () => {
    return(
        <div className="block-favorites">
            <h1>Oops! Você ainda não favoritou imóveis</h1>
            <h2>Veja abaixo como adicionar um imóvel aos favoritos</h2>

            <div className="block-wrapper">
                <h2>
                    Na página de busca por imóveis 
                    Clique no <span>Coração</span> na parte inferior do card do imóvel
                </h2>
                <div className='box-img'></div>                
            </div>

            <h4>Ou se preferir: </h4>

            <div className="btn-content">                        
                <Botao to="/aluguel/todos-os-tipos/todos-os-bairros-e-cidades" text='Ir para Seção de aluguel' />
                <Botao to="/venda/todos-os-tipos/todos-os-bairros-e-cidades" text='Ir para Seção de Vendas' />
            </div>

        </div>
    );
}

export default NoFavorites;