import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import Botao from '../../components/globals/Botao';

import './styles.css';


export default class Error404 extends Component {
    componentDidMount() {
        const $ = window.$;
        var preLoder = $("#preloader");
        preLoder.fadeOut(0);
    }
 
    render() {
        return (
            <Fragment>
                <Helmet title={`Error 404 | ${process.env.REACT_APP_FULL_NAME}`} />
            
                <main id="container404">
                    <div className="content">
                        <h2>Error 404</h2>
                        <h1>Página não encontrada</h1>

                        <p>
                            Não encontramos a página que você está procurando. Pode ser que você tenha digitado o endereço 
                            da página incorretamente.
                        </p>

                        <Link to="/" >Clique aqui para voltar a nossa página inicial</Link>

                        <h4>Ou se preferir: </h4>

                        <div className="btn-content">                        
                            <Botao to="/aluguel/apartamento/fortaleza" text='Ir para Seção de aluguel' />
                            <Botao to="/venda/apartamento/fortaleza" text='Ir para Seção de Vendas' />
                        </div>
                    </div>
                </main>
            </Fragment>
        );
    }
}