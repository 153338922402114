import React, { Component } from 'react';
import SelectOptions from './SelectOptions';
import { Input } from 'reactstrap';
import LoadingMask from "react-loadingmask";
import NumberFormat from "react-number-format";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Utils from '../../../../helpers/Utils';

import './styles.css';

const maxNumber = 100;

export default class FormSearch extends Component {
    state = {
        totalCaracteristicasImovel: maxNumber,
        totalCaracteristicasEdificio: maxNumber
    }

    closeSearch = () => {
        this.props.buscar();
        this.props.close();
    };

    redirect = (url) => {
        window.location = url;
    }

    render() {
        const todosTipos = [
            { category: '', name: 'Todos os tipos', value: 'todos-os-tipos' }
        ];

        const todosBairrosCidades = [
            { category: 'Todos', name: 'Todos os bairros e cidades', value: 'todos-os-bairros-e-cidades' }
        ];

        const listBairros =
            this.props.bairros.map((bairro) => (
                { category: 'Bairros', name: Utils.ucWords(bairro.text), value: Utils.transliterate(bairro.id) }
            ))
        ;

        const listCidades =
            this.props.cidades.map((cidade) => (
                { category: 'Cidades', name: Utils.ucWords(cidade.text), value: Utils.transliterate(cidade.id) }
            ))
        ;

        return (            
            <div id="result-form">
                <LoadingMask loading={this.props.loadingContent} >
                <form className="widget widget-sidebar-search-wrap">
                    <div className="widget-sidebar-search">
                        <div className="content-destaque">
                            <div className="widget-sidebar-item-wrap">
                                <button
                                    type="button"
                                    className={`btn ${this.props.modalidade !== "aluguel" ? 'inactive' : ''}`}
                                    onClick={()=>this.redirect(`/aluguel/${Utils.transliterate(this.props.listTipos.toString()).replace(/,/g, '+')}/${(this.props.listEnderecos.toString()).replace(/,/, '+')}${this.props.adicionarFiltros}`)}
                                >
                                    Aluguel
                                </button>
                                <button
                                    type="button"
                                    className={`btn float-right ${this.props.modalidade !== "venda" ? 'inactive' : ''}`}
                                    onClick={()=>this.redirect(`/venda/${Utils.transliterate(this.props.listTipos.toString()).replace(/,/g, '+')}/${(this.props.listEnderecos.toString()).replace(/,/, '+')}${this.props.adicionarFiltros}`)}
                                >
                                    Venda
                                </button>
                            </div>

                            <div className="widget-sidebar-item-wrap rld-single-input">
                                <SelectOptions 
                                    name='Enderecos'
                                    valueTodos='todos-os-bairros-e-cidades'
                                    selecteds={this.props.listEnderecos}
                                    options={todosBairrosCidades.concat(listCidades).concat(listBairros)}
                                    getOptions={this.props.getEnderecos}                                   
                                />
                            </div>                       

                            <div className="widget-sidebar-item-wrap rld-single-select">
                                <SelectOptions 
                                    name='Tipos'
                                    valueTodos='todos-os-tipos'
                                    selecteds={this.props.listTipos}
                                    options={todosTipos.concat(this.props.tipos)}
                                    getOptions={this.props.getTipos}
                                />                                
                            </div>

                            <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                                <div className="title">Preço R$</div>
                                <div className="price">
                                    <div className="rld-single-input">
                                        <NumberFormat
                                            name="valorInicial"
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={this.props.filtros.valorInicial}
                                            onKeyPress={e => this.props.handleEnter(e)}
                                            onChange={e => this.props.handleOnChange(e)}
                                            className="form-control"
                                            id="valorInicial"
                                            placeholder="R$"
                                        />
                                    </div>
                                    <div className="rld-single-input">
                                        <NumberFormat
                                            name="valorFinal"
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={this.props.filtros.valorFinal}
                                            onKeyPress={e => this.props.handleEnter(e)}
                                            onChange={e => this.props.handleOnChange(e)}
                                            className="form-control"
                                            id="valorFinal"
                                            placeholder="R$"
                                        />
                                    </div>
                                </div>                                    
                            </div>
                            
                            <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                                <div className="title">Área m²</div>
                                <div className="price">
                                    <div className="rld-single-input">
                                        <NumberFormat
                                            name="areaInicial"
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={this.props.filtros.areaInicial}
                                            onKeyPress={e => this.props.handleEnter(e)}
                                            onChange={e => this.props.handleOnChange(e)}
                                            className="form-control"
                                            id="areaInicial"
                                            placeholder="m²"
                                        />
                                    </div>
                                    <div className="rld-single-input">
                                        <NumberFormat
                                            name="areaFinal"
                                            thousandSeparator='.'
                                            decimalSeparator=','
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowNegative={false}
                                            value={this.props.filtros.areaFinal}
                                            onKeyPress={e => this.props.handleEnter(e)}
                                            onChange={e => this.props.handleOnChange(e)}
                                            className="form-control"
                                            id="areaFinal"
                                            placeholder="m²"
                                        />
                                    </div>
                                </div>                                       
                            </div>

                        </div>                        
                        <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                            <div className="title d-inline-block float-left mb-0 pt-2">Quartos</div>
                            <div className="rld-single-select d-inline-block float-right">
                                <Input
                                    type="select"
                                    name="quartos"
                                    className="select single-select"                            
                                    value={this.props.filtros.quartos}
                                    onChange={(e) => this.props.handleSelectFilters(e)}
                                >
                                    <option value={''}>-</option>
                                    <option value={1}>1+</option>
                                    <option value={2}>2+</option>
                                    <option value={3}>3+</option>
                                    <option value={4}>4+</option>
                                    <option value={5}>5+</option>
                                </Input>                                
                            </div>
                        </div>

                        <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                            <div className="title d-inline-block float-left mb-0 pt-2">Suítes</div>
                            <div className="rld-single-select d-inline-block float-right">
                                <Input
                                    type="select"
                                    name="suites"
                                    className="select single-select"                            
                                    value={this.props.filtros.suites}
                                    onChange={(e) => this.props.handleSelectFilters(e)}
                                >
                                    <option value={''}>-</option>
                                    <option value={1}>1+</option>
                                    <option value={2}>2+</option>
                                    <option value={3}>3+</option>
                                    <option value={4}>4+</option>
                                    <option value={5}>5+</option>
                                </Input>
                            </div>
                        </div>

                        <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                            <div className="title d-inline-block float-left mb-0 pt-2">Banheiros</div>
                            <div className="rld-single-select d-inline-block float-right">
                                <Input
                                    type="select"
                                    name="banheiros"
                                    className="select single-select"                            
                                    value={this.props.filtros.banheiros}
                                    onChange={(e) => this.props.handleSelectFilters(e)}
                                >
                                    <option value={''}>-</option>
                                    <option value={1}>1+</option>
                                    <option value={2}>2+</option>
                                    <option value={3}>3+</option>
                                    <option value={4}>4+</option>
                                    <option value={5}>5+</option>
                                </Input>
                            </div>
                        </div>

                        <div className="widget-sidebar-item-wrap rld-single-select-wrap mb-0">
                                <div className="title d-inline-block float-left mb-0 pt-2">Vagas</div>
                                <div className="rld-single-select d-inline-block float-right">
                                    <Input
                                        type="select"
                                        name="vagas"
                                        className="select single-select"                            
                                        value={this.props.filtros.vagas}
                                        onChange={(e) => this.props.handleSelectFilters(e)}
                                    >
                                        <option value={''}>-</option>
                                        <option value={1}>1+</option>
                                        <option value={2}>2+</option>
                                        <option value={3}>3+</option>
                                        <option value={4}>4+</option>
                                        <option value={5}>5+</option>
                                    </Input>
                                </div>
                            </div>                                    
                    </div>
                    
                    {this.props.caracteristicas !== null && this.props.caracteristicas.length > 0 ?
                        <div className="box-feature-list">                    
                            <div className="title">Imóvel</div>

                            <div className="box-feature-checked">
                                {this.props.caracteristicas.slice(0, this.state.totalCaracteristicasImovel).map((caracteristicas, index) => (                          
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox 
                                                name={caracteristicas.name}
                                                value={caracteristicas.value ?? ''}
                                                onChange={(e) => this.props.handleCheckedList(e)} 
                                                color="secondary"
                                            />
                                        }
                                        label={caracteristicas.name}
                                    />                       
                                ))}
                            </div>                        
                        </div>
                    : ''}
                    
                    {this.props.caracsCondominio !== null && this.props.caracsCondominio.length > 0 ? 
                        <div className="box-feature-list">                    
                            <div className="title">Edifício</div>

                            <div className="box-feature-checked">
                                {this.props.caracsCondominio.slice(0, this.state.totalCaracteristicasEdificio).map((caracsCondominio, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox 
                                                name={caracsCondominio.name}
                                                value={caracsCondominio.value ?? ''}
                                                onChange={(e) => this.props.handleCheckedListCondominio(e)} 
                                                color="secondary"
                                            />
                                        }
                                        label={caracsCondominio.name}
                                    />                       
                                ))}                                
                            </div> 
                        </div>
                    : ''}
                    &nbsp;
                    <div className="btn-wrap">
                        <button type="button" onClick={this.closeSearch} className="btn btn-primary">Enviar dados</button>
                    </div>                   
                </form>
                </LoadingMask>
            </div>
        );
    }
}