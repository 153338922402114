import BaseServices from '../../../../helpers/BaseServices';
import { API_CADIMO5 } from "./constants";

export default class Services extends BaseServices {
    constructor() {
        super(API_CADIMO5);
    };

    destaqueEspecial = () => {
        return fetch(`${API_CADIMO5}CarregaDestaques/1/true`, BaseServices._getRequestInfo())
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }

    destaques = () => {
        return fetch(`${API_CADIMO5}CarregaDestaques/6/false`, BaseServices._getRequestInfo())
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }
}