import React from "react"
import ContentLoader from "react-content-loader"

const LoaderCards = (props) => (
  <ContentLoader 
  height={1000} width={900}
    {...props}
  >
    <rect x="5" y="0" rx="10" ry="10" width="290" height="214" /> 
    <rect x="5" y="236" rx="0" ry="0" width="160" height="20" /> 
    <rect x="5" y="274" rx="0" ry="0" width="290" height="30" /> 
    <rect x="5" y="323" rx="0" ry="0" width="113" height="20" /> 
    <circle cx="57" cy="383" r="15" /> 
    <circle cx="148" cy="384" r="15" /> 
    <circle cx="243" cy="384" r="15" />

    <rect x="303" y="0" rx="10" ry="10" width="290" height="214" /> 
    <rect x="303" y="236" rx="0" ry="0" width="160" height="20" /> 
    <rect x="303" y="274" rx="0" ry="0" width="290" height="30" /> 
    <rect x="303" y="323" rx="0" ry="0" width="113" height="20" /> 
    <circle cx="357" cy="383" r="15" /> 
    <circle cx="448" cy="384" r="15" /> 
    <circle cx="543" cy="384" r="15" />

    <rect x="600" y="0" rx="10" ry="10" width="290" height="214" /> 
    <rect x="600" y="236" rx="0" ry="0" width="160" height="20" /> 
    <rect x="600" y="274" rx="0" ry="0" width="290" height="30" /> 
    <rect x="600" y="323" rx="0" ry="0" width="113" height="20" /> 
    <circle cx="657" cy="383" r="15" /> 
    <circle cx="748" cy="384" r="15" /> 
    <circle cx="843" cy="384" r="15" />

    <rect x="5" y="435" rx="10" ry="10" width="290" height="214" /> 
    <rect x="5" y="671" rx="0" ry="0" width="160" height="20" />    

    <rect x="303" y="435" rx="10" ry="10" width="290" height="214" /> 
    <rect x="303" y="671" rx="0" ry="0" width="160" height="20" />     

    <rect x="600" y="435" rx="10" ry="10" width="290" height="214" /> 
    <rect x="600" y="671" rx="0" ry="0" width="160" height="20" />

  </ContentLoader>
)

export default LoaderCards;