import React, { Component } from 'react';
import Headline from '../../../components/globals/HeadLine';
import CardImovel from '../../../components/globals/Cards/Imovel';

import Favorites from '../../../helpers/Favorites';
import NoFavorites from './NoFavorites';

import './styles.css';

export default class Content extends Component {
    constructor (props) {
        super(props);
        this._favoritos = new Favorites();        
    }
   
    render() {
        let content;
        if (this._favoritos.getQtdFavorites() > 0) {
            content = <CardImovel list={this._favoritos.getFavorites()} icon='trash'/>;           
        } else {
            content = <NoFavorites/>;
        }

        return (
            <main id='favorites-main'>
                <div className="container">
                    <Headline label="Meus imóveis favoritos" />
                    <div className="content">
                        { content }
                    </div>
                </div>
            </main>
        );
    }        
}