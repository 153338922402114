import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Utils from './../../../../helpers/Utils'

const Breadcrumbs = ({modalidade, tipo, endereco }) => {
    return (
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to='/'>Torres de Melo Neto</Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
                <Link to={`/${modalidade}/${(tipo.toString()).replace(/,/g,'+')}/${(endereco.toString()).replace(/,/g,'+')}`}>
                    {`Imóveis para ${modalidade}`}
                </Link>
            </BreadcrumbItem>

            <BreadcrumbItem active>
                {Utils.capitalize(Utils.retiraHifen(tipo.toString())).replace(/,/g,', ')}
            </BreadcrumbItem>

        </Breadcrumb>
    );
}
export default Breadcrumbs;