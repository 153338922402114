import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Favorites from '../../../../helpers/Favorites';
import ComparativesProperties from '../../../../helpers/ComparativesProperties';
import Utils from "../../../../helpers/Utils";
import Loader from './Loader';
import Tour360 from '../Label/Tour360';
import indisponivel from '../../../../assets/indisponivel.jpg';

import './styles.css';


export default class CardImovel extends Component {
    constructor(props){
        super(props);
        this._favorites = new Favorites();
        this._comparativesProperties = new ComparativesProperties();
        this.state = {
            loader: true
        }
    }    

    getSlug(tipo, quartos, suites, bairro, cidade, estado) {
        let dormitorios = 0;
        let slug = '';

        dormitorios = (quartos > 0 ? quartos : suites);
        if (dormitorios === 1) {
            slug = `${tipo}-${dormitorios}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else if (dormitorios > 1) {
            slug = `${tipo}-${dormitorios}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else {
            slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
        }

        return Utils.transliterate(slug);
    }

    render(){

        return (
            <Fragment>
            
            {this.props.list.map((card, index) => (               
                <div key={index} id={`card_${index}`} className="card-imovel-item">
                    <div className="single-feature">
                    
                        <div className="thumb">
                                <Loader />
                                {card.tour !== '' ? <Tour360 tour={card.tour} /> : ''}
        
                                <Link to={`/detalhes/${card.modalidade.toLowerCase()}/${card.id}/${this.getSlug(card.tipo, card.quartos, card.suites, card.bairro, card.cidade, card.estado)}`}> 
                                    
                                    <img src={ (card.hasOwnProperty('imagens') ? card.imagens[0].imagem : indisponivel) } alt={ (card.hasOwnProperty('imagens') ? card.imagens[0].descricao : 'Imagem indisponível') }/>
                                </Link>                                                     
                        </div>

                        <div className="details">                        
                            <p className="author"><i className="fa fa-user" /> { card.cidade }</p>
                            <h6 className="title readeal-top">
                                <Link to={`/detalhes/${card.modalidade.toLowerCase()}/${card.id}/${this.getSlug(card.tipo, card.quartos, card.suites, card.bairro, card.cidade, card.estado)}`}>
                                    { card.titulo }
                                </Link>
                            </h6>
                            <h6 className="price">{`R$ ${card.valor}`}</h6>

                            {card.destaque ?
                                <span className='especial-label'><i className="fa fa-star" /> Destaque</span>
                                : ''                            
                            }
                            
                            <ul className="info-list">
                                <li><i className="fa fa-bed"/>{ card.quartos } quartos</li>
                                <li><i className="fa fa-bath"/>{ card.banheiros } wc</li>
                                <li><i className="fa fa-object-ungroup"/>{ card.area_total }m²</li>
                            </ul>

                            <ul className="contact-list">
                                <li>
                                    <button type="button" className="phone" onClick={() => this._favorites.update(card)}>
                                        <i id={`icon_${card.id}`} className={this._favorites.getIcons(card.id, this.props.icon)} />
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="phone" onClick={() => this._comparativesProperties.update(card)}>
                                        <i id={`icon-comparatives-${card.id}`} className={this._comparativesProperties.getIcons(card.id)} />
                                    </button>
                                </li>
                                <li className="readeal-top">
                                    <Link className="btn btn-primary" to={`/detalhes/${card.modalidade.toLowerCase()}/${card.id}/${this.getSlug(card.tipo, card.quartos, card.suites, card.bairro, card.cidade, card.estado)}`}> Mais detalhes</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ))}           
            </Fragment>
        );
    }
}