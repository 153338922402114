const fields = {
    modalidade:'',
    tipo: '',
    endereco: '',
    listTipos: ['apartamento'],
    listEnderecos: ['todos-os-bairros-e-cidades'],
    imoveis: [],
    caracteristicas: [],
    caracsCondominio: [],
    total: '',
    loadingContent: true,
    data: [],
    tipos:[
        {
            'name': 'Selecione',
            'value': 0
        }
    ],
    bairros: [],
    cidades: [],
    order:'0',
    limit: 12,
    offset: 0,
    page: 1,
    filtros: {
        'valorInicial':'',
        'valorFinal':'',
        'areaInicial':'',
        'areaFinal':'',
        'quartos':'',
        'suites':'',
        'banheiros':'',
        'vagas':''
    },
    caracSelecionados:[],
    caracSelecionadosCondominio: []
};

export default fields;