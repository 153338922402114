import React from 'react';
import './tour360.css';
import tour360 from '../../../../assets/images/icons/360-2.png';

const Tour360 = (props) => {
    if (props.tour !== '') {
        return (
            <div className='label-360'>
                <a href={props.tour} target="_blank" rel="noopener noreferrer" >
                    <img src={tour360} alt="tour360" /> Tour
                </a>
            </div>
        );
    } else {
        return '';
    }
}

export default Tour360;