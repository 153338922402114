import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CardImovel from '../../../../components/globals/Cards/Imovel';
import Services from './Services';
import Utils from '../../../../helpers/Utils';

import LoaderEspecial from '../../Destaques/Loaders/LoaderEspecial';

export default class AluguelGrid extends Component {
  state = {
    imovel: [],
    imoveis: []
  }
  
  constructor(props) {
    super(props);
    this._services = new Services();
  }
  
  componentDidMount() {
    this._services.destaqueEspecial()
        .then(api => {
          this.setState({
            imovel: api.imoveis
          });          
        });

    this._services.destaques()
        .then(api => {
          this.setState({
            imoveis: api.imoveis
          });
        });
  }

  getSlug(tipo, quartos, suites, bairro, cidade, estado) {
    let dormitorios = 0;
    let slug = '';

    dormitorios = (quartos > 0 ? quartos : suites);
    if (dormitorios === 1) {
        slug = `${tipo}-${dormitorios}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
    } else if (dormitorios > 1) {
        slug = `${tipo}-${dormitorios}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
    } else {
        slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
    }

    return Utils.transliterate(slug);
  }

  render(){
    const imovel = this.state.imovel.length !== 0 ? this.state.imovel[0] : '';

    return(
      <div className="row grid-cards-destaques">
        <div className="col-xl-6 col-lg-8 item-destaque">
          {imovel !== '' ?
            <div className="single-leading-feature">
              <Link to={`/detalhes/${imovel.modalidade.toLowerCase()}/${imovel.id}/${this.getSlug(imovel.tipo, imovel.quartos, imovel.suites, imovel.bairro, imovel.cidade, imovel.estado)}`}></Link>
              <div className="slf-overlay" />
              <div className="thumb destaque">                
                <img src={imovel.imagens[0].imagem} alt={imovel.imagens[0].descricao} />
              </div>
              <div className="details">
                <h4 className="title readeal-top"><Link to={`/detalhes/${imovel.modalidade.toLowerCase()}/${imovel.id}/${this.getSlug(imovel.tipo, imovel.quartos, imovel.suites, imovel.bairro, imovel.cidade, imovel.estado)}`}>{ imovel.titulo }</Link></h4>
                <h5 className="price">R$ { imovel.valor }</h5>
                <span></span>
              </div>
            </div>
          :
            <LoaderEspecial />
          }
        </div>       
          {this.state.imoveis.length === 0 ?
            '' :
            <CardImovel list={this.state.imoveis} icon='heart' />
          }        
      </div>
    );
  }
}