import React from "react"
import ContentLoader from "react-content-loader"

const LoaderEspecial = (props) => (
  <ContentLoader
    height={400} width={1140}
      {...props}
    >
    <rect x="5" y="0" rx="10" ry="10" width="500" height="300" /> 

    <rect x="550" y="0" rx="10" ry="10" width="270" height="210" /> 
    <rect x="550" y="236" rx="0" ry="0" width="160" height="20" /> 
    <rect x="550" y="274" rx="0" ry="0" width="270" height="30" /> 

    <rect x="850" y="0" rx="10" ry="10" width="270" height="210" /> 
    <rect x="850" y="236" rx="0" ry="0" width="160" height="20" /> 
    <rect x="850" y="274" rx="0" ry="0" width="270" height="30" />
  </ContentLoader>
);

export default LoaderEspecial;