import React, { Component }from 'react';
import FormLocation from './components/FormLocation';
import FormCode from './components/FormCode';

import './styles.css';

export default class Banner extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            form: 'SearchLocation',
            labelBtn: 'Busca por código'
        }
    }

    showForm() {
        let form, label;
        if (this.state.form === 'SearchLocation') {
            form = 'SearchCode';
            label = 'Busca por Localização';            
        } else {
            form = 'SearchLocation';
            label = 'Busca por código';           
        }

        this.setState({
            form: form,
            labelBtn: label            
        });
    }

    render() {
        return(
            <div className="banner-area jarallax" id="search-section">
            <div className="container">
                <div className="banner-inner-wrap">
                <div className="row">
                    <div className="col-12">
                        <div className="banner-search-wrap">

                            {this.state.form === 'SearchLocation' ? 
                                <FormLocation {...this.props} /> : 
                                <FormCode {...this.props} /> }

                            <span
                                className="btn-code-location"
                                onClick={()=>this.showForm()}>{this.state.labelBtn}
                            </span>

                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div> 
        ); 
    }  
}