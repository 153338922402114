import BaseServices from './../../helpers/BaseServices';
import { API_VENDAS, API_CADIMO5 } from "./constants";
import RequestUtils from '../../config/requestUtils';

export default class Services extends BaseServices {
    constructor() {
        super(API_VENDAS, API_CADIMO5);
    };

    getDetalhesImovel = (modalidade, codigo) => {
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('detalhes', modalidade);

        url += `${method}${codigo}`;

        return fetch(`${url}`, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }

    solicitarInformacoesImovel = (dados) => {
        return fetch(`${API_VENDAS}/email/solicitarInformacoesImovel`, BaseServices._getRequestInfo('POST', `${JSON.stringify(dados)}`))    
          .then(response => {
            if (!response.ok) {
              throw response.json();          
            }
            return response.json();
          });
    }
    

}