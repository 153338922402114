import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { AnimateOnChange } from 'react-animation'
import classnames from 'classnames';

import Headline from '../../../components/globals/HeadLine';
import AluguelGrid from './Aluguel';
import VendaGrid from './Venda';
import * as constants from '../../Home/constants';

import './styles.css';

export default function Destaques(props) {
    const [activeTab, setActiveTab] = useState(constants.DESTAQUE_ALUGUEL);

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    let Customclass = props.Customclass ? props.Customclass : 'pd-top-60'

    return (
        <section id="imoveis-destaques">
            <div className={"featured-area  "+Customclass}>
                
                <Headline label="Imóveis em destaques" />

                <div className="container">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === constants.DESTAQUE_ALUGUEL })}
                                onClick={() => { toggle(constants.DESTAQUE_ALUGUEL); }} >
                                Aluguel
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === constants.DESTAQUE_VENDA })}
                                onClick={() => { toggle(constants.DESTAQUE_VENDA); }} >
                                Venda
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <AnimateOnChange>
                    <TabContent activeTab={activeTab}>                    
                        <TabPane tabId={constants.DESTAQUE_ALUGUEL}>                            

                            <AluguelGrid />
                           
                        </TabPane>  
                        <TabPane tabId={constants.DESTAQUE_VENDA}>
                        
                            <VendaGrid />
          
                        </TabPane> 
                    </TabContent>
                    </AnimateOnChange>
                    
                </div>
            </div>
        </section>
    );    
}
