import React, { Component } from 'react';
import { Input, Button } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import Swal from 'sweetalert2';
import LoadingMask from "react-loadingmask";
import Services from '../Services';

export default class FormBuyers extends Component {
    constructor(props){
        super(props);
        this._services = new Services(); 
        this.state = {
            codigo: '',
            nome: '',
            email: '',
            telefone: '',
            celular: '',
            mensagem: '',
            loading: false
        }
    }

    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    clearInputs = () => {        
        this.setState({
            codigo: '',
            nome: '',
            email: '',
            telefone: '',
            celular: '',
            mensagem: ''
        });        
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({loading: true});

        let dados = 
        {   
            "codigo": this.state.codigo,
            "nome": this.state.nome,
            "email": this.state.email,
            "telefone": this.state.telefone,
            "celular": this.state.celular,
            "mensagem": this.state.mensagem
        }

        this._services.queroComprarImovel(dados)
            .then(response => {
                this.setState({loading: false});

                if (response.code === 1) {
                    this.getAlert('success', 'Dados enviado com sucesso!');
                    this.clearInputs();                    
                }
                if (response.code === 2) {
                    this.getAlert('error', 'Ooops! Erro no envio dos dados.');
                } 
            });
    }

    getAlert = (icon, message) => {
        return Swal.fire({
            position: 'center',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
        });
    }
    render() {
        return (
            <LoadingMask loading={this.state.loading}>
            <div id="block-form-buyers" className="block-form">
                <h2>Envie suas informações e aguarde nosso contato</h2>
                <form onSubmit={(event) => this.handleClick(event)}>
                    <div className="widget-sidebar-item-wrap rld-single-input col-md-12">
                        <Input 
                            name="codigo" 
                            type="text" 
                            placeholder="Digite o código do imóvel"
                            required={true}
                            onChange={event => this.handleOnChange(event)}
                            value={this.state.codigo}
                        />
                    </div>

                    <div className="widget-sidebar-item-wrap rld-single-input col-md-12">
                        <Input 
                            name="nome" 
                            type="text" 
                            placeholder="Seu nome"
                            required={true}
                            onChange={event => this.handleOnChange(event)}
                            value={this.state.nome}
                        />
                    </div>

                    <div className="widget-sidebar-item-wrap rld-single-input col-md-12">
                        <Input 
                            name="email" 
                            type="email" 
                            placeholder="Seu e-mail"
                            required={true}
                            onChange={event => this.handleOnChange(event)}
                            value={this.state.email}
                        />
                    </div>

                    <div className="row">
                        <div className="widget-sidebar-item-wrap rld-single-input col-md-6">
                            <MaskedInput                                        
                                mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Seu telefone"                            
                                guide={false}
                                name='telefone'
                                onChange={event => this.handleOnChange(event)}
                                value={this.state.telefone}
                            />
                        </div>
                        <div className="widget-sidebar-item-wrap rld-single-input col-md-6">
                            <MaskedInput
                                mask={['(', /[1-9]/, /\d/, ')', ' ',/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                placeholder="Seu celular"                            
                                guide={false}
                                required={true}
                                name='celular'
                                onChange={event => this.handleOnChange(event)}
                                value={this.state.celular}
                            />
                        </div>                             
                    </div>

                    <div className="widget-sidebar-item-wrap rld-single-input col-md-12">
                        <Input
                            name='mensagem'
                            type="textarea" 
                            placeholder="Descreva, em detalhes, todas as condições de sua proposta (preço, forma de pagamento, prazo, etc.)"
                            onChange={event => this.handleOnChange(event)}
                            value={this.state.mensagem}
                        />
                    </div>

                    <div className="btn-wrap col-md-6">
                        <Button type="submit" className="btn">Realizar meu cadastro</Button>
                    </div>                                
                </form>
            </div>
            </LoadingMask>
        );
    }
}