import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import CardImovel from '../../../components/globals/Cards/Imovel';
import FormSearch from './FormSearch';
import MapResult from './MapResult';
import SidebarForm from './SidebarForm';
import SidebarMap from './SidebarMap';

import LoaderTitle from './Loaders/LoaderTitle';
import LoaderCards from './Loaders/LoaderCards';
import LoadingMask from "react-loadingmask";
import Utils from '../../../helpers/Utils';

import './styles.css';

export default class Content extends Component {    
    componentWillReceiveProps(nextProps) {
        if (nextProps.offset === 0) { 
            this.setState({page: 1});
        }
    }

    openSidebarMap = () => {
        document.querySelector('#overlay').classList.add('overlay');
        document.querySelector('#sidebar-map').classList.add('open-sidebar-map');          
    }

    closeSidebarMap = () => {
        document.querySelector('#overlay').classList.remove('overlay');      
        document.querySelector('#sidebar-map').classList.remove('open-sidebar-map');      
    }

    closeSidebarForm = () => {
        document.querySelector('#overlay').classList.remove('overlay');      
        document.querySelector('#sidebar-form').classList.remove('open-sidebar-form');      
    }    

    render() {
        let title = '';
        if (this.props.total > 0) {
            title = `
            ${this.props.total} imóve${this.props.total > 1 ? 'is':'l'}  
            ${this.props.listTipos.indexOf("todos-os-tipos") === -1 ?
                `do tipo ${Utils.retiraHifen(this.props.listTipos.toString()).toLowerCase().replace(/,/g,', ')}`:
                'de todos os tipos'
            }
            ${this.props.modalidade === 'venda' ? ' à venda ' : ' para alugar '} em 
            ${this.props.listEnderecos.indexOf("todos-os-bairros-e-cidades") === -1 ?
                Utils.ucWords(Utils.retiraHifen(this.props.listEnderecos.toString()).replace(/,/g, ', ')) :
                'todos os bairros e cidades'
            }`;
        } else {
            title = 'Não foram encontrados imóveis para a busca realizada';
        }

        let paginacao;
        if (this.props.total > this.props.limit) {
            paginacao = 
            
                <div className="content-pagination">
                    <nav>
                    <LoadingMask loading={this.props.loadingContent} >
                        <Pagination
                            hideDisabled
                            prevPageText='Anterior'
                            nextPageText='Próximo'
                            firstPageText='Início'
                            lastPageText='Fim'
                            activePage={this.props.page}
                            itemsCountPerPage={this.props.limit}
                            totalItemsCount={parseInt(this.props.total)}
                            onChange={this.props.handlePageChange}
                        />
                    </LoadingMask>
                    </nav>
                </div>
        } else {
            paginacao = ''
        }

        return (            
            <div id="search-result">
                <div id="overlay" onClick={this.closeSidebarForm}></div>
            
                <div className="container">
                    <div className="head-line">
                        {
                            this.props.loadingContent ?
                            <LoaderTitle/> :
                            <h1>{ title }</h1>
                        }
                    </div>

                    <div className="search-result-wrapper">                    
                        <div id="form-sidebar">                        
                            <FormSearch
                                {...this.props}
                                handleSelect={this.props.handleSelect}
                                handleSelectFilters={this.props.handleSelectFilters}
                                handleEnter={this.props.handleEnter}
                                handleOnChange={this.props.handleOnChange}
                                handleCheckedList={this.props.handleCheckedList}
                                handleCheckedListCondominio={this.props.handleCheckedListCondominio}
                                adicionarFiltros={this.props.adicionarFiltros}
                                getTipos={this.props.getTipos}
                                getEnderecos={this.props.getEnderecos}
                            />
                        </div>

                        <div id="result-card" className="result-card">
                            <div className="content-cards">
                                {
                                    this.props.loadingContent ?
                                    <LoaderCards/> :
                                    <CardImovel list={this.props.imoveis} icon='heart' />
                                }
                            </div>

                            { paginacao }

                        </div>
                        <div id="result-map" className="result-map">
                            <MapResult list={this.props.imoveis} />
                        </div>
                    </div>
                </div>

                <div className="cotent-footer">                    
                    <button onClick={this.openSidebarMap}>
                        <i className="fa fa-map-marker"/> Ver no mapa
                    </button>
                </div>

                <SidebarForm 
                    {...this.props}
                    close={this.closeSidebarForm} 
                    buscar={this.props.buscar}
                    handleSelect={this.props.handleSelect}
                    handleSelectFilters={this.props.handleSelectFilters}
                    handleEnter={this.props.handleEnter}
                    handleOnChange={this.props.handleOnChange}
                    handleCheckedList={this.props.handleCheckedList}
                    handleCheckedListCondominio={this.props.handleCheckedListCondominio}
                />

                <SidebarMap close={this.closeSidebarMap} imoveis={this.props.imoveis} />

            </div>
        );
    }        
}