import Util from '../helpers/Utils';

const favorites = JSON.parse(localStorage.getItem('favorites')) || [];

export default class Favorites {
    getFavorites = () => favorites;

    getQtdFavorites = () => favorites.length;

    addIcon(id) {
        let element = document.getElementById(`icon_${id}`);
        element.setAttribute('class', 'fa fa-heart'); 
    }

    removeIcon(id) {
        let element = document.getElementById(`icon_${id}`);
        element.setAttribute('class', 'fa fa-heart-o');   
    }

    deleteCardFavorito(id) {
        let element = document.getElementById(`icon_${id}`);

        if (element.getAttribute("class") === 'fa fa-trash') {
            let card = element.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;           
            Util.fadeOut(card.getAttribute('id'), 0.5);
            setTimeout(function(){
                card.style.display = 'none'
            }, 500);
        } else {
           this.removeIcon(id);
        }
    }

    update = (card) => {
        let existsInLocalStorage = favorites.findIndex((item) => item.id === card.id);
        let totalizadorFavorites = document.getElementById('totalizadorFavorites');

        if (existsInLocalStorage === -1) {
            favorites.push(card);
            this.addIcon(card.id);            
        } else {
            favorites.splice(existsInLocalStorage, 1);
            this.deleteCardFavorito(card.id);
        }
        
        localStorage.setItem('favorites', JSON.stringify(favorites));
        totalizadorFavorites.innerHTML = this.getQtdFavorites();              
    }  

    getIcons = (id, icon) => {
        let isFavorites = favorites.findIndex((item) => item.id === id);

        if (isFavorites === -1) {
            return `fa fa-${icon}-o`;
        } else {
            return `fa fa-${icon}`;
        }        
    }    
}