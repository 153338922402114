import BaseServices from '../../../../helpers/BaseServices';
import { API_VENDAS } from "./constants";

export default class Services extends BaseServices {
    constructor() {
        super(API_VENDAS);
    };

    destaqueEspecial = () => {
        return fetch(`${API_VENDAS}/destaqueEspecial`, BaseServices._getRequestInfo('venda'))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }

    destaques = () => {
        return fetch(`${API_VENDAS}/destaques`, BaseServices._getRequestInfo('venda'))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }
}