import React, { Component } from 'react';
import Headline from "./../../../components/globals/HeadLine";
import Tags from '../../../components/globals/Tags';

import './styles.css';

const api = [
    {
        text: 'Casa à venda no Meireles',
        to: '/venda/casa/meireles'
    },
    {
        text:  'Salas para alugar',
        to: '/aluguel/sala/todos-os-bairros-e-cidades'
    },
    {
        text : 'Casa para vender',
        to : '/venda/casa/todos-os-bairros-e-cidades'
    },
    {
        text : 'Casa à venda na Aldeota',
        to : '/venda/casa/aldeota'
    },
    {
        text : 'Apartamento para alugar na Aldeota',
        to : '/aluguel/apartamento/aldeota'
    },
    {
        text : 'Salas à venda',
        to : '/venda/sala/todos-os-bairros-e-cidades'
    },
    {
        text : 'Salas para alugar',
        to : '/aluguel/sala/todos-os-bairros-e-cidades'
    },
    {
        text : 'Casa para alugar',
        to : '/aluguel/casa/todos-os-bairros-e-cidades'
    },
    {
        text : 'Salas para alugar no Benfica',
        to : '/aluguel/sala/benfica'
    },
    {
        text : 'Salas para alugar no Montese',
        to : '/aluguel/sala/montese'
    },
    {
        text : 'Loja para alugar Centro',
        to : '/aluguel/loja/centro'
    },
    {
        text : 'Salas para alugar no Benfica',
        to : '/aluguel/sala/benfica'
    },
    {
        text : 'Salas à venda',
        to : '/venda/sala/todos-os-bairros-e-cidades'
    },
];

class ImoveisMaisPopulares extends Component {
    state = {
        tags: []
    }

    componentDidMount() {
        this.loadCards();
    }

    loadCards = () => {
        this.setState({tags: api})
    }

    render() {
        return (
            <section id="mais-populares">

                <Headline label="Busca mais populares" />

                <div className="polular-search-list">
                    <div className="container">
                        
                        <Tags data={this.state.tags} />

                    </div>
                </div>

            </section>
        );
    }
}

export default ImoveisMaisPopulares;