import React, {Component} from 'react';
import Topo from '../../../components/Layout/Header/Topo';
import Navbar from '../../../components/Layout/Header/Navbar';

export default class Header extends Component{
    render() {
        return (
            <header>
                <Topo />
                <Navbar {...this.props} flag={true}/>
            </header>
        )
    }
}