import React from 'react';
import Topo from './Topo';
import Navbar from './Navbar';

export default function Header() {   

    return (
        <header>
            <Topo />
            <Navbar />
        </header>
    )
}