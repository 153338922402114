import React, { Component } from 'react';
import Headline from '../../../components/globals/HeadLine';

import ComparativesProperties from '../../../helpers/ComparativesProperties';
import NoComparatives from './NoComparatives';

import Utils from  '../../../helpers/Utils';

import './styles.css';

export default class Content extends Component {
    constructor (props) {
        super(props);
        this._comparativesProperties = new ComparativesProperties();
    }
     
    render() {
        const listData = this._comparativesProperties.getComparativesProperties()

        let content;
        if (this._comparativesProperties.getQtdComparativesProperties() > 0) {
            content = 
            <div className="content-wrapper">
                <div className="content">
                    <div className="card-comparatives col-main">
                        <ul>
                            <li className="title">Informações do Imóvel</li>
                            <li>Código</li>
                            <li>Oferta</li>
                            <li>Tipo de imóvel</li>
                            <li>Bairro</li>
                            <li>Endereço</li>
                            <li>Área privativa</li>
                            <li>Quartos</li>
                            <li>Suítes</li>
                            <li>Vagas de garagem</li>
                            <li className="title">Preço do Imóvel</li>
                            <li>Valor</li>
                            <li>Tipo</li>
                            <li className="title">Remover Imóvel</li>
                            <li>Clique no botão para remover</li>
                        </ul>
                    </div>

                    {listData.map((card, index) => (
                        <div id={`card-comparatives-${card.id}`} key={index} className="card-comparatives">
                            <ul>
                                <li><img src={ card.imagens[0].imagem} alt={card.endereco}/> </li>
                                <li className="code">{ card.codigo }</li>
                                <li>{ Utils.capitalize(card.modalidade) }</li>
                                <li>{ card.tipo }</li>
                                <li>{ card.bairro }</li>                                    
                                <li>{ card.endereco }, { card.cidade}-{card.estado}</li>                                  
                                <li>{ card.area_total }m²</li>                                
                                <li>{ card.quartos}</li>                              
                                <li>{ card.suites}</li>                           
                                <li>{ card.vagas}</li>                        
                                <li className="clear"></li>
                                <li>{ card.valor}</li>                     
                                <li>{ Utils.capitalize(card.modalidade) === "aluguel" ? "Mensal" : "--"}</li>                
                                <li className="clear"></li>           
                                <li className="remove-comparate" onClick={() => this._comparativesProperties.remove(card)}><i className="fa fa-trash"/> &nbsp;&nbsp;remover</li>                                    
                            </ul>
                        </div>
                    ))}
                </div>
            </div>       
        } else {
            content = <NoComparatives/>;
        }

        return (
            <main id='comparative-properties-main'>
                <div className="container">
                    <Headline label="Escolha o imóvel ideal para você" />

                    {content}

                </div>          
            </main>
        );
    }        
}