import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import Header from './Header';
import Banner from './Banner';
import Destaques from './Destaques';
import MaisPopulares from './Populares';
import Vantagens from './Vantagens';
import Footer from '../../components/Layout/Footer';
import ArtigosBlog from './ArtigosBlog';
import Carrossel from './Carrossel';

export default class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render(){
        return (
            <Fragment>
                <Helmet title={`${process.env.REACT_APP_FULL_NAME}`} />
                <Header {...this.props}/>
                <main>
                    <Banner {...this.props} />
                    <Carrossel />
                    <Destaques />
                    <MaisPopulares />
                    <Vantagens />
                    <ArtigosBlog />
                </main>
                <Footer />
            </Fragment>
        );
    }  
}
