import React, { useState }from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { AnimateOnChange } from 'react-animation'
import classnames from 'classnames';

import Headline from "./../../../components/globals/HeadLine";

import Proprietarios from "./Proprietarios";
import Inquilinos from "./Inquilinos";
import Compradores from "./Compradores";
import * as constants from '../../Home/constants';

import './styles.css';

export default function Vantagens() {
    const [activeTab, setActiveTab] = useState(constants.VANTAGEM_INQUILINO);

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <section id="vantagens">          
            <Headline label="Por que ser nosso cliente?" />
            <div className="container">                    
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === constants.VANTAGEM_INQUILINO })}
                            onClick={() => { toggle(constants.VANTAGEM_INQUILINO) }} >
                            Inquilinos
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === constants.VANTAGEM_PROPRIETARIO })}
                            onClick={() => { toggle(constants.VANTAGEM_PROPRIETARIO) }} >
                            Proprietários
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === constants.VANTAGEM_COMPRADOR })}
                            onClick={() => { toggle(constants.VANTAGEM_COMPRADOR) }} >
                                Compradores
                        </NavLink>
                    </NavItem>
                </Nav>

                <AnimateOnChange>
                <TabContent activeTab={activeTab}>                    
                    <TabPane tabId={constants.VANTAGEM_INQUILINO}>                           
                        
                        <Inquilinos/>
                            
                    </TabPane>  
                    <TabPane tabId={constants.VANTAGEM_PROPRIETARIO}>                            
                        
                        <Proprietarios/>
                            
                    </TabPane>
                    <TabPane tabId={constants.VANTAGEM_COMPRADOR}>                            

                        <Compradores/>
                          
                    </TabPane>  
                </TabContent>
                </AnimateOnChange>
            
            </div>                
        </section>
    );
}

