import React from 'react';
import Botao from '../../../components/globals/Botao';

import './styles.css';

const NoComparatives = () => {
    return(
        <div className="content-comparatives">
            <div className="block-comparatives">
                <h1>Oops! Você ainda não adicionou imóveis para comparar</h1>
                <h2>Veja abaixo como adicionar um imóvel para a página de comparativos</h2>

                <div className="block-wrapper">
                    <h2>
                        Na página de busca por imóveis 
                        Clique no botão de <span>Comparativos de imóveis</span> na parte inferior do card do imóvel
                    </h2>
                    <div className='box-img'></div>                
                </div>

                <h4>Ou se preferir: </h4>

                <div className="btn-content">
                    <Botao to="/aluguel/todos-os-tipos/todos-os-bairros-e-cidades" text='Ir para Seção de aluguel' />
                    <Botao to="/venda/todos-os-tipos/todos-os-bairros-e-cidades" text='Ir para Seção de Vendas' />
                </div>
            </div>
        </div>
    );
}

export default NoComparatives;