import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

import banner1 from  '../../../assets/images/banners/anuncio.jpg';
import banner2 from  '../../../assets/images/banners/shoppingtirol.jpg';
import banner3 from  '../../../assets/images/banners/riviera.jpg';


import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

const items = [
  {
    src: banner1,
    linkBanner: 'https://page.torresdemeloneto.com.br/cadastre-seu-imovel',
    altText: 'Cadastre seu Imóvel',
    key: 1,
  },
  {
    src: banner2,
    linkBanner: 'https://shoppingtiroleusebio.com.br/',
    altText: 'Shopping Tirol Eusébio',
    key: 2,
  },
  {
    src: banner3,
    linkBanner: 'https://page.torresdemeloneto.com.br/bela-vista-aquiraz-riviera',
    altText: 'Bela Vista Aquiraz Riviera',
    key: 3,
  },
];

const Carrossel = (args) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
  
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };
  
    const slides = items.map((item) => {
      return (
        
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <Link to={{pathname: item.linkBanner }} target="_blank" >
                    <img src={item.src} alt={item.altText} />
                </Link>
            </CarouselItem>
      );
    });
  
    return (
        <section className='carousel-area'>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                enableTouch={true}
                interval={3000}
                fade={true}
                {...args}
            >
                <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
                />
                <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
                />
            </Carousel>
        </section>

    );
}

export default Carrossel;