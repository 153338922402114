import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => (
  <ContentLoader 
    speed={2}
    width={290}
    height={210}
    viewBox="0 0 290 210"
    backgroundColor="#ededed"
    foregroundColor="#fdafaf"
    {...props}
  >
    <circle cx="113" cy="99" r="8" /> 
    <circle cx="147" cy="99" r="8" /> 
    <circle cx="178" cy="98" r="8" />
  </ContentLoader>
)

export default Loader