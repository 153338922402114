import React, { Fragment } from 'react';
import './style.css';
import Headline from './../../components/HeadLine/HeadLine';
import ScrollIntoView from './../../components/scrollIntoView';
import Helmet from 'react-helmet';

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';

const PrivatePolicy = () => {
    return (
      <Fragment>
        <Helmet title={`Nossa Política de Privacidade | ${process.env.REACT_APP_FULL_NAME}`} />
        <Header />
        <ScrollIntoView>
        <main id="private-main"> 
            <div className="politica-de-privacidade">            
                <div className="container">
                    <div className="card">                        
                        <Headline label="Nossa Política de Privacidade para Clientes e Fornecedores" />
                            <h2>1.	CONTEXTUALIZAÇÃO </h2>

                           <p> A Imobiliária Torres de Melo Neto LTDA, pessoa jurídica de direito privado, inscrita no Cadastro Nacional de Pessoas Jurídicas, sob o número 10.490.341/0001-46, estabelecida na cidade de Fortaleza, estado do Ceará, à Rua Professor Dias da Rocha, n.º 162, altos, bairro Meireles, CEP: 60170-310, entende como relevante o legítimo tratamento dos dados pessoais dos usuários durante a execução dos serviços prestados pela empresa, servindo a presente Política de Privacidade para regular, de forma simples, transparente e objetiva, quais dados e informações serão tratados, assim como quando os mesmos poderão ser utilizados.</p>

                          <p>  A presente Política de Privacidade e Proteção de Dados Pessoais tem como objetivo descrever as regras adotadas pela Imobiliária Torres de Melo Neto quanto à coleta, registro, armazenamento, uso, compartilhamento, enriquecimento e eliminação (“Tratamento”) de Dados Pessoais, bem como as medidas de segurança e privacidade adotadas. Este documento faz parte do programa de compliance em Proteção de Dados Pessoais da imobiliária.</p>

                           <p> O Programa de Compliance, por sua vez, é todo processo regulatório que converge para o Programa de Conformidade da LGPD, envolvendo a interpretação da legislação, definição das obrigações legais, diagnóstico dos fatos pertinentes e relevantes, além do levantamento de fluxos e processos que contribuem ou não para a legitimação documental.</p>

                          <p>  Diante do exposto, a Imobiliária Torres de Melo Neto, consciente da importância e necessidade de adequar as suas operações de tratamento de dados pessoais a uma nova e ampla regulação sobre o tema, no caso a LGPD, deu início, em 2022 ao seu processo de conformidade. </p>

                          <p>  Fato é que a Imobiliária Torres de Melo Neto realiza inúmeras operações de tratamento de dados pessoais, sempre buscando resguardar o interesse e os direitos dos titulares de dados, que é basilar em nossa ética empresarial pautada na credibilidade da família Torres de Melo, o que a inseriu em lugar de destaque do mercado imobiliário do estado. </p>

                          <p>  Assim, uma vez identificada como Controladora de Dados Pessoais, de acordo com as definições da LGPD, a empresa tem o compromisso de cumprir as regras de privacidade e proteção de dados aplicáveis. Essas atividades abrangem uma série de particularidades nos tratamentos de dados pessoais realizados em sua estrutura, muitas vezes possuindo sinergia com o campo da proteção de dados, como coleta, tratamento e compartilhamento com fim de execução dos serviços prestados. </p>

                         <p>   Em suma, a leitura atenta desta Política se faz relevante pois facilitará a compreensão do novo procedimento. Por isso, ao acessar o website e tomar conhecimento dos serviços oferecidos pela Imobiliária Torres de Melo Neto ou ao contratá-los, deve-se declarar ter conhecimento da presente Política e estar ciente sobre as informações aqui presentes.</p>
                            
                            <h2>2.	ESCOPO </h2>

                          <p>  A presente Política de Privacidade estabelece as diretrizes da Imobiliária Torres de Melo Neto para resguardo e uso de dados pessoais que venham a ser tratados em suas atividades, tendo como referência a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/2018).</p>

                          <p>  Destaca-se o direcionamento desta norma especificamente aos clientes e fornecedores da Imobiliária, pelos motivos e especificidades que passaremos a enunciar.</p>

                            <h2>3.	DESTINÁRIOS </h2>

                          <p>  Esta Política se aplica (i) a todos os terceiros, sejam eles pessoas físicas ou jurídicas que atuam em operações que envolvam tratamento de dados pessoais realizadas no escopo das atividades conduzidas pela Torres de Melo Neto; (ii) aos agentes de tratamento de dados pessoais externos à Torres de Melo Neto que, por meio de contrato de fornecimento, mantenha vínculo com a imobiliária; e (iii) aos titulares de dados pessoais que firmem contratos de serviços com a imobiliária, e cujos dados são tratados pela Torres de Melo Neto. </p>

                           <p> A adesão ao programa de conformidade da Imobiliária Torres de Melo Neto às leis de proteção de dados pessoais e aos diplomas normativos dele decorrentes, incluindo a presente Política, é obrigatória a todos os destinatários acima indicados na medida em que se relacionam com a imobiliária. Todas as operações que envolvam tratamento de dados pessoais que sejam realizadas no escopo das atividades conduzidas pela Imobiliária Torres de Melo Neto estão sujeitas a tais normativas.</p>


                            <h2>4.	  APLICABILIDADE </h2>

                          <p>  Esta Política estabelece diretrizes e regras para garantir que seus destinatários entendam e cumpram as legislações que versam sobre proteção de dados pessoais em todas as interações com titulares de dados pessoais, terceiros e agentes de tratamento, seja na coleta, utilização, ou na transmissão realizada por ou em nome da Imobiliária Torres de Melo Neto. O que inclui dados pessoais registrados em papel, mantidos em sistemas de computador ou dispositivos portáteis, bem como dados pessoais contidos em banco de dados e softwares.</p>

                            <h2>5.	  OBJETIVOS DESSA POLÍTICA DE PRIVACIDADE </h2>

                           <p> São objetivos da Política de Privacidade e Proteção de Dados Pessoais da Imobiliária Torres de Melo Neto:</p>

                            I.	Estabelecer as diretrizes e responsabilidades da empresa que assegurem e reforcem o compromisso da instituição com a proteção de dados pessoais;<br></br>
                            II.	Descrever as regras a serem seguidas na condução das atividades e operações de tratamento de dados pessoais realizadas pela imobiliária e pelos destinatários desta Política, no âmbito das atividades da empresa, que garantem a sua conformidade com as legislações de proteção de dados, em especial, com a Lei Geral de Proteção de Dados.<br></br>
                            III.	Oferecer aos titulares a consulta facilitada e gratuita sobre a forma e a duração do tratamento de dados pessoais realizada pela empresa;<br></br>
                            IV.	Disponibilizar informações sobre suas atividades de processamento de forma clara, precisa e de fácil acesso, conforme preceitua a Lei Geral de Proteção de Dados em seu artigo 6°, VI. <br></br>
                            A presente Política de Privacidade presta observância aos princípios da transparência e da prestação de contas, fornecendo informações de forma clara, adequada e ostensiva aos titulares, conforme preceitua o Art. 9°, caput, da Lei 13.709/2018 (Lei Geral de Proteção de Dados).<br></br>

                            <h2>6.	COMO NÓS COLETAMOS SEUS DADOS</h2>

                            <p>O Tratamento de Dados Pessoais realizado pela Imobiliária Torres de Melo Neto será feito somente dentro dos limites e finalidades aqui estabelecidos nesta Política de Privacidade. </p>

                           <p> É importante ressaltar que quando a base legal para tratamento de Dados Pessoais for o consentimento, a Imobiliária Torres de Melo Neto garantirá que ele será colhido de forma livre, inequívoca e informada, e em observância a todos os requisitos legais aplicáveis.</p>

                            <p>Além disso, a Imobiliária Torres de Melo Neto realiza a coleta dos dados necessários para atender as finalidades previstas nesta Política, assegurando que todo tratamento de dado pessoal seja realizado nos limites da Lei Geral de Proteção de Dados e respeitando as bases legais permitidas para a execução de seus serviços. </p>

                           <p> Nós coletamos seus dados pessoais sempre que o titular (Clientes Torres de Melo Neto):  </p>

                            I.	Conceder, temporariamente, documentos requeridos pela Imobiliária, com intuito de coleta de chave de imóvel para visitação;<br></br>
                            II.	Solicitar proposta ou na concessão de dados para agendamento de reuniões preliminares;<br></br>
                            III.	Conceder dados específicos com o escopo de passar por análise de viabilidade de cliente;<br></br>
                            IV.	Fornecer seus dados através de cumprimento de checklist de documentos requeridos ao início de cada relação contratual;<br></br>
                            V.	Fornecer seus dados para atualização perante órgãos administrativos;<br></br>
                            VI.	Oferecer todo e qualquer dados necessário e indispensável para a consecução da finalidade de execução do contrato;<br></br>
                            VII.	Participar de pesquisas de satisfação; <br></br>
                            VIII.	Oferecer e-mail, nome e telefone (opcional) com o intuito de ser comunicado na solução de ticket de ajuda aberto no site da imobiliária;<br></br>
                            IX.	Enviar os dados para viabilizar a emissão de notas fiscais de serviços e boletos; <br></br>
                            X.	Entregar dados de cartão de crédito, para parcelamento de pagamento de seguro fiança contratado junto a empresa parceira da imobiliária (previsão expressamente estipulada pela controladora que receberá o pagamento);<br></br>
                            XI.	Fornecer dados necessários ao acompanhamento de pendência fiscal, processual ou administrativa, ou no interesse de exercício de defesa em qualquer destas searas;<br></br>

                            <p>Por fim, são coletados os dados de fornecedores da Imobiliária Torres de Melo Neto através de contratos de fornecimento de bens e/ou serviços, nota avulsa, folha de pagamento, recibo ou boleto para comprovação de despesas. O armazenamento é feito primordialmente, de forma digital, em nosso sistema interno CADIMO, e, eventualmente, de forma física nos arquivos localizados n e os dados são utilizados nos processos de ressarcimento de despesas, controle e anotações.</p>


                            <h2>6.1.	UTILIZAÇÃO DE COOKIES. </h2>

                           <p> Cookies são pequenos arquivos de texto armazenados em seu navegador ou dispositivo. Eles permitem que determinadas informações sobre você sejam coletadas. Utilizamos esses dados de tráfego e informações coletadas, a fim de gerar relatórios sobre as tendências no uso da Plataforma ou do Serviço, e para determinar a efetividade de nossas campanhas promocionais. </p>

                           <p> Nesta perspectiva, a Imobiliária Torres de Melo Neto utiliza Cookies e outras tecnologias de rastreamento para medir e rastrear atividades gerais do usuário em nosso site. Essas tecnologias podem ser próprias ou de terceiros, para garantir que nossa plataforma funcione adequadamente, e para oferecer aos usuários uma experiência mais personalizada.</p>
                            
                           <p> Essas informações são coletadas num formato agregado e anônimo, a fim de determinar as necessidades de nossos usuários, promover certos produtos ou serviços, ou suportes adicionais para otimizar nossos esforços de marketing e publicidade. Por exemplo, nós podemos compilar estatísticas sobre a porcentagem de nossos usuários em um estado ou país que possuem interesses ou finalidades específicas, ou para criar publicidades ou desenvolver novos recursos que aparecem em nossa Plataforma.</p>

                            <h2>6.2.	TERMOS DE CONSENTIMENTO </h2>

                           <p> Nos casos de tratamento de dados em que a base legal utilizada for o consentimento do usuário, esse consentimento será concedido de forma livre, inequívoca, informada e expressa.</p>

                            <h2>7.	DADOS PESSOAIS COLETADOS PELO TORRES DE MELO NETO</h2>

                           <p> Nos casos dos titulares-CLIENTES, a Torres de Melo Neto coleta os seguintes dados: </p>

                            <p>a)	Dados Pessoais informados pelos Titulares (Clientes):</p>

                            	Dados cadastrais e para execução de contrato como: Nome, Data de Nascimento, Endereço, RG, CPF, Estado Civil, Profissão, Dados Financeiros, dados patrimoniais e assinatura<br></br>
                            	Dados de contato como: Telefone e E-mail.<br></br><br></br>

                           <p> Por fim, nos casos de titulares-FORNECEDORES, o Torres de Melo Neto coleta os seguintes dados:</p>

                            <p>	Nome, Endereço, CPF, Telefone, Dados Financeiros. Eventualmente, o e-mail pode ser coletado quando este for a “chave-pix” necessária para efetivação de pagamento.</p>

                           <h2> 8.	COMO OS DADOS SÃO UTILIZADOS </h2>

                           <p> Utilizamos seus Dados Pessoais conforme as bases legais e hipóteses elencadas no artigo 7° da Lei 13.709/2018 (Lei Geral de Proteção de Dados), bem como para fornecer nossos serviços de forma satisfatória e para garantir a segurança e transparência de nossas operações perante você e as autoridades brasileiras. Além disso, seus Dados Pessoais também são importantes para a manutenção e implementação de mecanismos de qualidades para nossos serviços, pois usamos essas informações para garantir que tudo funcione como o esperado por você e para desenvolver serviços mais adequados às suas necessidades.</p>

                           <p> Conforme a Lei Geral de Proteção de Dados estabelece, o Tratamento de Dados Pessoais deve obedecer ao princípio da finalidade, devendo ser feito somente mediante propósitos legítimos, específicos, explícitos que devem ser informados a você, sem possibilidade de Tratamento posterior de forma incompatível com essas finalidades. </p>

                           <p> Os Dados Pessoais coletados dos usuários que utilizam os serviços da Imobiliária Torres de Melo Neto (clientes) podem ser utilizados para as seguintes finalidades: </p>

                            I.	Para cadastro e preenchimento de contrato;<br></br>
                            II.	Para geração de recibos;<br></br>
                            III.	No preenchimento de procurações específicas dirigidas à ENEL e CAGECE;<br></br>
                            IV.	Para fins de cobrança e controle de inadimplência (e toda e qualquer formalidade que viabilize tais atos);<br></br>
                            V.	Para elaboração de planilhas com as notas fiscais de serviços emitidas, para envio ao cliente e ao setor de contabilidade;<br></br>
                            VI.	Para emissão de certidões junto aos órgãos públicos e cartórios;<br></br>
                            VII.	Para autorização e identificação das partes contratuais;<br></br>
                            VIII.	Para eventuais pesquisas de satisfação/relacionamento com cliente/feedbacks;<br></br>
                            IX.	Para o envio de material de mail marketing, desde que mantido o nexo do assunto envolvido, e garantido o direito do opt-out;<br></br>
                            X.	Para elaboração e execução dos contratos;<br></br>
                            XI.	Para melhorias nas vendas e no relacionamento com clientes;<br></br>
                            XII.	Para alterações junto à Receita Federal, Prefeitura e outros órgãos públicos, quando no cumprimento de ordem legal; <br></br>
                            XIII.	Para práticas bancárias necessárias ao cumprimento das obrigações contratuais, em que os dados compartilhados somente o serão com as autorizações e permissões prévias;<br></br>
                            XIV. Para divulgação via e-mail marketing dos serviços e soluções da Imobiliária Torres de Melo Neto e empresas integrantes do seu grupo;<br></br>

                           <p> Em relação aos fornecedores do Torres de Melo Neto, os Dados Pessoais são coletados para as seguintes finalidades: </p>

                            I.	Formalização contratual com objeto/finalidade definida.<br></br>
                            II.	Armazenamento em banco de dados para futuras compras/contratações de serviços.   <br></br>
                            III.	Para ressarcimento de despesas, controle e anotações dos contratos de clientes do Torres de Melo Neto.<br></br>

                           <p> É importante ressaltar que as finalidades descritas acima se referem às atividades nas quais a Imobiliária Torres de Melo Neto atua como Controladora de Dados Pessoais.</p>

                            <p>Como regra, a coleta de dados na Imobiliária Torres de Melo Neto tem como base legal a execução de contrato, além do cumprimento de obrigação legal e consentimento fornecido pelo titular. </p>

                          <p>  Quando a base legal para tratamento de Dados Pessoais for o consentimento, a Imobiliária Torres de Melo Neto garantirá que ele será colhido de forma livre, inequívoca e informada, e em observância a todos os requisitos legais aplicáveis.</p>

                           <p> O Tratamento de Dados Pessoais na Imobiliária Torres de Melo Neto será feito somente dentro dos limites e finalidades aqui estabelecidas, sendo restrito o acesso aos Dados Pessoais apenas a funcionários e a pessoas autorizadas pela empresa, na medida em que seja necessário o acesso para a execução do Tratamento, na forma prevista nesta Política.</p>

                           <h2> 9.	 ARMAZENAMENTO DE DADOS </h2>

                           <p> Os dados pessoais coletados pelo Torres de Melo Neto serão mantidos enquanto existir uma relação contratual entre o titular e a empresa, ou enquanto: (i) houver razões empresariais justificadas; (ii) necessário para cumprir os objetivos descritos nesta Política de Privacidade; (iii) um período de retenção mais longo for exigido ou autorizado por lei, tudo de acordo com o previsto em nossa Política de Retenção e Descartes.</p>

                           <p> Algumas informações poderão ser eliminadas caso o titular assim solicite, a qualquer momento. Nesse caso, iremos analisar a solicitação de acordo com as leis aplicáveis, fornecendo uma resposta em até 15 (quinze) dias úteis. </p>

                            <p>Não havendo justificativa para manter seus dados pessoais armazenados, estes serão excluídos ou, caso seja útil à Torres de Melo Neto a manutenção destes, iremos torná-los anônimos, desassociando as informações do titular, de modo a não permitir a sua identificação como indivíduo. </p>

                           <p> Se a anonimização não for possível (por exemplo, porque seus dados pessoais foram armazenados em arquivos de backup), a Imobiliária Torres de Melo Neto assegura que irá armazenar seus dados pessoais com segurança, isolando-o de qualquer outro processamento até que a exclusão seja possível.</p>

                           <h2> 10.	COMPARTILHAMENTO DE DADOS </h2>

                           <p> Os Dados Pessoais dos titulares poderão ser compartilhados com terceiros parceiros, autoridades e órgãos reguladores, desde que estritamente necessário na consecução da finalidade que rege a relação. Podemos compartilhar seus dados com nossos setores internos, fornecedores de serviços, bancos, instituições financeiras, instituições públicas, sistemas, plataformas e softwares terceiros, sempre buscando atender às devidas finalidades e uma melhor execução dos serviços. </p>

                            <p>É importante ressaltar que todo compartilhamento é efetuado dentro dos limites e finalidades dos nossos serviços e em estrita conformidade com a Legislação Aplicável de Proteção de Dados. </p>

                            <p>E mais, os Dados Pessoais dos Titulares podem também ser compartilhados com os seguintes terceiros:</p>

                                a) Autoridades, entidades governamentais ou outros terceiros, para a proteção dos interesses da Imobiliária Torres de Melo Neto, em qualquer tipo de conflito, como processos judiciais e/ou administrativos;
                                b) Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para a sua requisição.

                           <p> Vale esclarecer que nos casos em que a Imobiliária Torres de Melo Neto compartilhe Dados Pessoais com empresas terceiras, estas se comprometem a incluir cláusulas que regulem o Tratamento de Dados Pessoais, e a prestar observância às regras de confidencialidade sobre as informações compartilhadas nos respectivos contratos.</p>

                            <p>Admitida e eventualidade de compartilhamento excepcional de dados pessoais com terceiros localizados no exterior, a Imobiliária Torres de Melo Neto se compromete a adotar todas as medidas de segurança necessárias para que o compartilhamento desses dados se dê de forma segura e em observância às disposições legais aplicáveis.</p>

                            <h2>10.1.	COMPARTILHAMENTO DE DADOS DOS CLIENTES E FORNECEDORES DA IMOBILIÁRIA TORRES DE MELO NETO </h2>

                           <p> Além do que já foi mencionado, é importante ressaltar que a Imobiliária Torres de Melo Neto resguarda o direito de trabalhar com sistemas e fornecedores terceiros, o que desencadeia o compartilhamento dos dados de titulares-clientes com ditos sistemas e fornecedores, sempre com o objetivo de alcançar as finalidades previamente apresentadas. </p>
                            <p>Assim, em resumo, os Dados Pessoais dos Clientes Torres de Melo Neto poderão ser compartilhados, a depender do caso, com: </p>

                                a) Setores internos do Torres de Melo Neto;<br></br>
                                b) Plataformas de softwares e sistemas de operadores terceiros;<br></br>
                                c) Receita Federal e Prefeitura;<br></br>

                           <p> Em relação aos Fornecedores, seus dados pessoais poderão ser compartilhados, a depender do caso, com:</p>

                            a)	Setores interno da Imobiliária Torres de Melo Neto<br></br>
                            b)	Plataformas de softwares e sistemas de operadores terceiros;<br></br>
                            c)	Órgãos públicos<br></br>

                           <h2> 11.	SEGURANÇA DOS DADOS PESSOAIS </h2>

                           <p> Aspectos gerais:</p>

                           <p> A Imobiliária Torres de Melo Neto adota medidas técnicas, organizacionais e administrativas comercialmente razoáveis e compatíveis aos riscos de nossas atividades, a fim de proteger as informações que estão sob nosso controle contra acesso, uso, modificação, destruição, processamento ou divulgação não autorizados ou ilegais, e contra a perda, destruição ou dano acidental. </p>

                           <p> Nós limitamos o acesso dos nossos funcionários aos dados pessoais de nossos clientes para que apenas aqueles que efetivamente necessitam de acesso para executar as atividades associadas às suas responsabilidades profissionais possam acessá-los.</p>

                           <p> Devido à realidade da segurança dos dados, cumpre alertar que nenhum sistema de segurança ou de armazenamento pode ser garantido como 100% eficaz ou seguro.</p> 

                           <p> Isto é, não podemos garantir que nenhuma informação fornecida ou coletada por nós seja acessada, hackeada, divulgada, alterada ou destruída por terceiros não autorizados, ainda que tenhamos uma cultura em proteção de dados plenamente instalada na empresa. Por essa razão, o titular apenas deverá divulgar as informações que efetivamente sejam solicitadas por nós para a execução de nossos serviços, devendo resguardar informações sigilosas de seu interesse.</p>


                           <p> Incidentes de Segurança:</p>

                           <p> A Imobiliária Torres de Melo Neto se compromete a monitorar com frequência razoável e compatível com os riscos de nossas atividades os requisitos de confidencialidade, integridade e disponibilidade dos dados pessoais e demais informações em nosso poder. Mas caso tenhamos conhecimento de um incidente de segurança em nossos bancos de dados que possam resultar risco ou danos em razão do acesso, de terceiros não autorizados, comunicaremos com a maior brevidade possível o incidente à Autoridade Nacional, e aos titulares dos dados envolvidos no incidente, quando citado risco for relevante, na forma da Lei Geral de Proteção de Dados, conforme o explicitado em nossa política interna de resposta a incidentes.</p>

                           <h2> 12.	DIREITOS DO TITULAR DE DADOS </h2>

                           <p> A Imobiliária Torres de Melo Neto, no contexto das suas atividades de tratamento de dados pessoais, reforça o seu compromisso de respeito aos direitos dos titulares de dados pessoais, ressaltando que poderão solicitar:</p>

                            <p>DIREITO À CONFIRMAÇÃO DA EXISTÊNCIA DO TRATAMENTO: o titular de dados pessoais pode questionar, junto à Torres de Melo Neto, se há a realização de operações de tratamento relativos a seus dados pessoais;</p>

                            <p>DIREITO DE ACESSO: o titular de dados pessoais pode solicitar e receber uma cópia de todos os dados pessoais coletados e armazenados pela Imobiliária Torres de Melo Neto;</p>

                            <p>DIREITO DE CORREÇÃO: o titular de dados pessoais pode requisitar a correção de dados pessoais que estejam incompletos, inexatos ou desatualizados;</p>

                            <p>DIREITO DE ELIMINAÇÃO: o titular de dados pessoais pode requisitar a exclusão de seus dados pessoais de bancos de dados geridos pela imobiliária, salvo se houver um motivo legítimo para a sua manutenção, como eventual obrigação legal de retenção de dados ou estudo por órgão de pesquisa. Na hipótese de eliminação, a empresa se reserva o direito de escolher o procedimento de eliminação empregado, comprometendo-se a utilizar meio que garanta a segurança e evite a recuperação dos dados;</p>

                            <p>DIREITO DE SOLICITAR A SUSPENSÃO DE TRATAMENTO ILÍCITO DE DADOS PESSOAIS: a qualquer momento, o titular de dados pessoais poderá requisitar à Torres de Melo Neto a anonimização, bloqueio ou eliminação de seus dados pessoais que tenham sido reconhecidos por autoridade competente como desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD.</p>

                            <p>DIREITO DE OPOSIÇÃO A UM TRATAMENTO DE DADOS PESSOAIS: nas hipóteses de tratamento de dados pessoais não baseadas na obtenção do consentimento, o titular de dados pessoais poderá apresentar à Torres de Melo Neto uma oposição, que será analisada a partir dos critérios presentes na LGPD.</p>

                            <p>DIREITO À PORTABILIDADE DOS DADOS: o titular de dados pessoais poderá requisitar à Torres de Melo Neto que seus dados pessoais sejam disponibilizados a outro fornecedor de serviço ou produto, respeitados o segredo comercial e industrial da Instituição, os limites técnicos de sua infraestrutura e a regulamentação aplicável.</p>

                            <p>DIREITO À REVOGAÇÃO DO CONSENTIMENTO: o titular de dados pessoais tem direito a revogar o seu consentimento. Entretanto, ressalta-se que isso não afetará a legalidade de qualquer tratamento realizado antes da retirada. Na hipótese de revogação do consentimento, talvez não seja possível fornecer determinados serviços. Sendo este o caso, o titular de dados pessoais será informado.</p>

                            <p>REVISÃO DE DECISÕES AUTOMATIZADAS: O titular pode questionar as decisões tomadas unicamente com base em dados pessoais tratados de forma automatizada que afetem seus interesses, como por exemplo, decisões destinadas a definir seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade, bem como saber quais são os critérios e procedimentos utilizados para a decisão automatizada, observados os segredos comerciais e industriais.</p>

                            <p>O atendimento de seus direitos será realizado conforme os prazos previstos em lei. Conforme a Lei 13.709/2018 – LGPD ainda que a resposta possa ser feita de forma imediata e de maneira simplificada, ou por meio de declaração “clara e completa”, que indique a origem dos dados, os critérios usados e a finalidade do tratamento, o prazo para a resposta no formato completo é de até 15 dias contado a partir da data do requerimento.</p>

                           <p> Para exercer qualquer direito, fazer qualquer solicitação ou tirar dúvidas quanto à proteção de dados pessoais, basta acessar nosso canal de atendimento para questões inerentes aos dados pessoais, nos comunicando o seu pedido, conforme aduzido em nossa política de exercício de direitos. </p>

                           <p> Para que possamos atender às suas solicitações, pode ser necessário reter certas informações, tanto para fins de manutenção obrigatória de registro, quanto para que possamos fazer contato e, assim, atender sua solicitação e prestar as informações necessárias. </p>

                           <p> É imprescindível que o titular esteja ciente de que o seu pedido pode não ser aceito para análise caso não forneça a documentação necessária para comprovar sua identidade, que deverá ser reunida juntamente com a apresentação do pedido de exercício de direitos.</p>

                           <h2> 13.	DADOS DE CRIANÇAS E ADOLESCENTES  </h2>

                            <p>A Torres de Melo Neto, como regra padrão, não solicita nem coleta dados pessoais de crianças ou adolescentes. Caso venha a ter em nossos bancos de dados, informações de crianças e adolescentes, a imobiliária obriga-se a obter o consentimento válido junto aos pais/responsáveis legais da criança.</p>

                            <p>Nos casos em que a coleta de dados de crianças e adolescentes for justificada através do Cumprimento de obrigações legais, o tratamento de dados é justificado para o cumprimento de leis (por exemplo, a exigência de E-Social) e resguardo da empresa contra eventuais ações.</p>

                          <h2>  14.	TRANSFERÊNCIA INTERNACIONAL DE DADOS </h2>

                           <p> Nós da Imobiliária Torres de Melo Neto nos resguardamos no direito de tratar seus dados no Brasil ou delegar certos atos de tratamento para fornecedores e empresas sediadas no exterior. Por isso, buscamos, sempre que possível, contratar fornecedores que estejam em países com regulações robustas sobre proteção de dados pessoais, mas infelizmente não podemos garantir o correto manuseio por terceiros que utilizam, divulgam e protegem os dados pessoais de acordo com suas respectivas políticas de privacidade, apesar de nos aparelharmos ao máximo contra tais práticas.</p>

                           <p> Independentemente do caso, tomaremos todas as providências para garantir o cumprimento dessa Política de Privacidade e da legislação vigente, para garantir seus direitos relacionados à proteção de dados pessoais dentro e fora do Brasil, respeitando as diretrizes da LGPD.</p>

                          <h2>  15.	SEGURANÇA </h2>

                           <p> A Imobiliária Torres de Melo Neto aplica os padrões de segurança e melhores práticas adotadas no mercado para garantir a integridade de seus dados e impedir uma Violação de Dados Pessoais. </p> 

                            <p>Não obstante seus dados estarem seguros com a imobiliária, será de sua responsabilidade informar apenas os dados que forem solicitados, limitando-se à finalidade específica, não devendo compartilhar qualquer outro dado. </p> 

                          <h2>  16.	BASES LEGAIS </h2>

                           <p> Toda e qualquer coleta de dados pessoais realizada pela imobiliária encontra guarita nas hipóteses listadas no artigo 7° da LGPD. </p>
                           <p> A execução de contrato, o consentimento dado pelo Titular e o cumprimento de obrigação legal formam a quase integralidade das bases legais fundamentadoras dos processos de tratamento de dados pessoais por parte da Imobiliária Torres de Melo Neto. </p>

                          <h2>  17.	ATUALIZAÇÕES E ALTERAÇÕES DA POLÍTICA DE PRIVACIDADE  </h2>

                           <p> A versão atual da nossa Política de Privacidade está sempre disponível para acesso com a data da última atualização.</p>

                           <p> A Imobiliária Torres de Melo Neto se reserva no direito de alterar esta Política de Privacidade sempre que necessário, em resposta aos desenvolvimentos legais, técnicos, de mercado e negócios. Por isso, recomendamos que o titular a verifique periodicamente, para tomar ciência de eventuais alterações desde a última vez que tomou conhecimento.</p>

                           <p> Sempre que alterarmos nossa Política de Privacidade, tomaremos as medidas cabíveis para informar todos os usuários, de maneira correspondente com o significado das mudanças que realizamos, de acordo com as leis aplicáveis.</p>

                           <p> Caso o titular não concorde com tais alterações, deverá entrar em contato com a Imobiliária Torres de Melo Neto, por meio do canal de atendimento desta, a fim de buscar a eliminação de seus dados. Nos casos em que for necessário o consentimento do titular, este poderá ser revogado a qualquer tempo, a critério do titular.</p>

                          <h2>  18.	ENTRE EM CONTATO </h2>

                           <p> Caso você tenha qualquer dúvida ou precise se comunicar com o Torres de Melo Neto sobre assuntos que envolvem seus Dados Pessoais, entre em contato através de nosso site ou e-mail (<a href="mailto:ouvidoria@torresdemeloneto.com.br">ouvidoria@torresdemeloneto.com.br</a>) para fornecermos qualquer esclarecimento necessário. </p>

                    </div>
                </div>    
            </div>   
        </main>
        </ScrollIntoView>
        <Footer />
      </Fragment>
    );
}
export default PrivatePolicy;

