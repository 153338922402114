import React from 'react';
import {Link} from "react-router-dom";

import './styles.css';

const Tags = ({data}) => {
    return (       
        <ul className="list-label">
             {data.map((tag, index) => (               
                <li  key={index}>
                    <Link to={tag.to} className="text">{tag.text}</Link>
                </li>
            ))}            
        </ul>
    );
}

export default Tags;

