import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from './pages/Home';
import SearchResult from './pages/SearchResult';
import PropertyDetails from './pages/PropertyDetails';
import Favorites from './pages/Favorites';
import Error404 from './pages/Error404';
import ComparativeProperties from './pages/ComparativeProperties';
import AboutUs from './pages/AboutUs';
import PropertyRegistration from './pages/PropertyRegistration';
import Buyers from './pages/Buyers';
import PrivatePolicy from './pages/PrivatePolicy';

class Root extends Component {
    render() {
        return(
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/detalhes/:modalidade/:codigo/:slug?" component={PropertyDetails} />
                    <Route exact path="/:modalidade/:tipo/:endereco/:filtros?" component={SearchResult} />
                    <Route exact path="/favoritos" component={Favorites} />
                    <Route exact path="/comparativos-de-imoveis" component={ComparativeProperties} />
                    <Route exact path="/sobre-nos" component={AboutUs} />
                    <Route exact path="/cadastre-seu-imovel" component={PropertyRegistration} />
                    <Route exact path="/compradores" component={Buyers} />
                    <Route exact path="/politica-de-privacidade" component={PrivatePolicy} />
                    <Route exact path="*" component={Error404} />
                </Switch>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('realdeal'));
