import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import Utils from './../../helpers/Utils';
import Header from '../../components/Layout/Header';
import Imovel from './Imovel';
import Footer from '../../components/Layout/Footer';

import Services from './Services';

export default class SearchResult extends Component {

    constructor(props){
        super(props);
        this.state = {
            responsive:{
                0:{
                    items:1
                }
            },
            title: '',
            imovel: {tituloTextoDestaque:'...', imagens:[]},      
            modalidade: '',
            tipo: '',
            bairro: '',
            cidade: '',
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
            myloader: true
        } 
        this._services = new Services();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.handleLoadImovelDetails();
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.match.params.codigo !== undefined) {
            return {
                modalidade: nextProps.match.params.modalidade,
                codigo: nextProps.match.params.codigo
            };
        }
        return null;
    }

    handleLoadImovelDetails = () => {
        this._services.getDetalhesImovel(this.props.match.params.modalidade, this.props.match.params.codigo).then(
            response => {                
                if (response.hasOwnProperty('imovel')) {                   
                    this.setState({
                        imovel: response.imovel,
                        finalidade: this.props.match.params.modalidade,
                        tipo: response.imovel.tipo.toLowerCase(),
                        bairro: Utils.transliterate(response.imovel.bairro),
                        cidade: response.imovel.cidade.toLowerCase(),                        
                    });
                }

                this.setState({myloader: false})
            }
        )
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{`${this.state.imovel.tituloTextoDestaque} | ${process.env.REACT_APP_FULL_NAME}`}</title>
                    <meta name="title" content={this.state.imovel.tituloDetalhes} />
                    <meta name="description" content={this.state.imovel.descricao} />

                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content={this.state.imovel.tituloDetalhes} />
                    <meta property="og:description" content={this.state.imovel.descricao} />
                    <meta property="og:image" content={this.state.imovel.imagens.length  > 0 ? this.state.imovel.imagens[0].imagem : ''} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={window.location.href} />
                    <meta property="twitter:title" content={this.state.imovel.tituloDetalhes} />
                    <meta property="twitter:description" content={this.state.imovel.descricao} />
                    <meta property="twitter:image" content={this.state.imovel.imagens.length > 0 ? this.state.imovel.imagens[0].imagem : ''} />
                </Helmet>
                
                <Header />

                <Imovel {...this.state} myloader={this.state.myloader} codigo={this.props.match.params.codigo}/>

                <Footer />
            </Fragment>
        );
    }        
}