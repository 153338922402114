import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as Botao} from 'react-scroll';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import FormBuyers from './FormBuyers';
import Headline from '../../../components/globals/HeadLine';
import CardServices from "../../../components/globals/Cards/Servicos";
import Card from "../../../components/globals/Cards/Vantagens";

import consultor from '../../../assets/images/icons/consultor.svg'
import seguranca from '../../../assets/images/icons/seguranca.svg'
import contrato from '../../../assets/images/icons/contrato.svg'

import './styles.css';

const initialListServices = [
    {
        label: "Imóvel desejado",
        text: "Preencha o formulário com as informações do seu imóvel de seu interesse."
    },
    {
        label: "Especialista",
        text: "Seu contato será direcionado para um de nossos colaboradores especialistas que entrará em contato muito em breve."
    },
    {
        label: "Perfil",
        text: "Identificado seu perfil apresentaremos as melhores oportunidades para as suas necessidades."
    }
]

export default class Content extends Component {
    render() {
        return (
            <main id='buyers-main'>
                <div className="topo">                    
                    <div className="container">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to='/'>Torres de Melo Neto</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Compradores
                        </BreadcrumbItem>

                    </Breadcrumb>
                    </div>
                </div>
                <div className="container content">
                    <div className="row">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="section-title">
                                <h1 className="title">Compre seu imóvel na Torres de Melo Neto</h1>
                                <p>Somos uma das maiores referências do setor imobiliário da cidade com qualidade e segurança das transações.</p>
                            </div>

                            <CardServices listServices={initialListServices} />
                            
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <FormBuyers />
                        </div>
                    </div>
                </div>

                <div className="block-cards">
                    <div className="container">
                        <Headline label="Por que comprar com a gente?" />

                        <div className="content-cards">
                            <Card icon={consultor}>
                                Consultor imobiliário: Temos uma equipe de corretores e gerentes experientes para te auxiliar a fazer o melhor negócio
                            </Card>
                            <Card icon={seguranca}>
                                Segurança jurídica: Elaboramos um contrato com todo aparato jurídico para garantir maior segurança na intermediação
                            </Card>
                            <Card icon={contrato}>
                                Oportunidades de negócio: Buscamos as melhores opções disponíveis para o seu perfil
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="block-instruction">
                    <div className="container">
                        <Headline label="Quero comprar um imóvel, como faço?" />

                        <p>
                            Envie suas informações pelo formulário do início desta página. E pronto.
                            Entraremos em contato para agendar uma visita e seguir com os demais detalhes da compra.
                        </p>

                        <Botao 
                            to="block-form-buyers" 
                            smooth={true} 
                            duration={800}
                            offset={-130}
                            className="btn btn-primary"                            
                        >
                            Realizar meu cadastro
                        </Botao>
                    </div>
                </div>

            </main>
        );
    }        
}