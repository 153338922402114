import BaseServices from './../../helpers/BaseServices';
import { API_VENDAS } from "./constants";

export default class Services extends BaseServices {
  constructor() {
    super(API_VENDAS);
  };

  cadastrarImovel = (dados) => {
    return fetch(`${API_VENDAS}/email/cadastrarImovel`, BaseServices._getRequestInfo('POST', `${JSON.stringify(dados)}`))    
      .then(response => {
        if (!response.ok) {
          throw response.json();          
        }

        return response.json();
      });
  }
}