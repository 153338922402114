import React from 'react';
import './videoLink.css';
import videoLink from '../../../../assets/images/icons/video-play.png';

const VideoLink = (props) => {
    if (props.video !== '') {
        return (
            <div className='label-video-link'>
                <a href={props.video} target="_blank" rel="noopener noreferrer" >
                    <img src={videoLink} alt="videoLink" /> Video
                </a>
            </div>
        );
    } else {
        return '';
    }
}

export default VideoLink;