import React from 'react';
import {Link} from 'react-router-dom';

import './styles.css';

const Botao = ({to, text, ...rest}) => {        
    return (
        <div className="botao-box">
            <Link to={{pathname: to}} {...rest} className="btn"> {text} </Link>
        </div>
    );  
}

export default Botao