import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Utils from '../../../../helpers/Utils';


const Breadcrumbs = ({modalidade, tipo, cidade, bairro, estado, titulo}) => {
    return (
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to='/'>Torres de Melo Neto</Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
                <Link to={`/${modalidade}/${Utils.transliterate(tipo)}/${Utils.transliterate(cidade)}`}>
                    {`${modalidade === 'aluguel' ? 'Alugar' : 'Comprar'}`}
                </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
                <Link to={`/${modalidade}/${Utils.transliterate(tipo)}/${Utils.transliterate(cidade)}`}>
                    { tipo}
                </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
                <Link to={`/${modalidade}/${Utils.transliterate(tipo)}/${Utils.transliterate(bairro)}`}>
                    { bairro }, { cidade } - { estado }
                </Link>
            </BreadcrumbItem>

            <BreadcrumbItem active>
                { titulo }
            </BreadcrumbItem>

        </Breadcrumb>
    );
}
export default Breadcrumbs;