import Util from '../helpers/Utils';
import Swal from 'sweetalert2'

const comparativesProperties = JSON.parse(localStorage.getItem('comparativesProperties')) || [];

export default class ComparativesProperties {

    getComparativesProperties = () => comparativesProperties;
    getQtdComparativesProperties = () => comparativesProperties.length;

    getIcons = (id) => {
        let iscomparatives = comparativesProperties.findIndex((item) => item.id === id);

        if (iscomparatives === -1) {
            return `fa fa-clone`;
        } else {
            return `fa fa-check-square`;
        }        
    }  

    update = (card) => {
        let totalComparativos = this.getQtdComparativesProperties() + 1;
        let existsInLocalStorage = comparativesProperties.findIndex((item) => item.id === card.id);
        
        if (existsInLocalStorage > -1 ) {
            comparativesProperties.splice(existsInLocalStorage, 1);
            localStorage.setItem('comparativesProperties', JSON.stringify(comparativesProperties));
            this.removeIcon(card.id);
            return false
        }

        if (totalComparativos <= 3 && existsInLocalStorage === -1) {
            comparativesProperties.push(card);
            localStorage.setItem('comparativesProperties', JSON.stringify(comparativesProperties));
            this.addIcon(card.id)
        } else {
            this.getAlert();            
        }                      
    }

    remove(card) {
        let element = document.getElementById(`card-comparatives-${card.id}`);
        
        Util.fadeOut(`card-comparatives-${card.id}`, 0.5);             
        setTimeout(function(){
            element.style.display = 'none'
        }, 500);

        this.update(card);
    }

    addIcon(id) {
        let element = document.getElementById(`icon-comparatives-${id}`);
        if (element) {
            element.setAttribute('class', 'fa fa-check-square');
        }
    }

    removeIcon(id) {
        let element = document.getElementById(`icon-comparatives-${id}`);
        if (element) {
            element.setAttribute('class', 'fa fa-clone');  
        }         
    }

    getAlert = () => {
        return Swal.fire({
            icon: 'error',
            html:
              '<h5>Foi atigido o total máximo de <strong style="color:red">3 imóveis</strong> na página de comparativos</h5> <br/><br/>' +
              'Conferir a lista de imóveis na página de <a href="' + window.location.origin+'/comparativos-de-imoveis" <strong style="color:red"><strong>Comparativos de Imóveis</strong></a>',
            showCloseButton: true,
            showConfirmButton: false
        });
    }
}