import React, { Component } from 'react';
import Breadcrumbs from './Breadcrumbs';
import { Input } from 'reactstrap';
import Switch from '@material-ui/core/Switch';

import './styles.css';

export default class Topo extends Component  {

    state = {
        openForm: true,
        checkedMap: false
    }

    handleChangeMap = () => {
        this.setState({ ...this.state, openForm: false, checkedMap: !this.state.checkedMap });
        if (!this.state.checkedMap) {
            document.querySelector('#form-sidebar').classList.add('hidden-form');
            document.querySelector('#result-card').classList.add('viewe-map');
            document.querySelector('#result-map').classList.add('viewe-map');
        } else {
            document.querySelector('#form-sidebar').classList.remove('hidden-form');
            document.querySelector('#result-card').classList.remove('viewe-map');
            document.querySelector('#result-map').classList.remove('viewe-map');
            this.setState({openForm: true});
        }        
    };

    handleChangeForm = () => {
        this.setState({ ...this.state, openForm: !this.state.openForm, checkedMap: false });

        if (!this.state.openForm) {
            document.querySelector('#form-sidebar').classList.remove('hidden-form');
            document.querySelector('#result-card').classList.remove('viewe-map');
            document.querySelector('#result-map').classList.remove('viewe-map');
        } else {
            document.querySelector('#form-sidebar').classList.add('hidden-form');
            document.querySelector('#result-card').classList.remove('viewe-map');
            document.querySelector('#result-map').classList.remove('viewe-map');
        }
    }

    openSidebarForm = () => {
        document.querySelector('#overlay').classList.add('overlay');
        document.querySelector('#sidebar-form').classList.add('open-sidebar-form');          
    }  

    render() {
        return (
            <div id="content-topo-result">
                <div className="container">
                    <Breadcrumbs 
                        modalidade={this.props.modalidade}
                        tipo={this.props.listTipos}
                        endereco={this.props.listEnderecos}
                    />

                    <div id="search-filters-topo">
                        <div className="col-left">

                            <button className="btn-filtros-mobile" onClick={this.openSidebarForm}>
                                <i className="fa fa-search"/> &nbsp;Filtros
                            </button>

                            <button className={`btn-filtros-result ${this.state.openForm ? 'active' : ''}`}  onClick={this.handleChangeForm} >
                                <i className="fa fa-power-off" />&nbsp;|
                                Filtros
                            </button>

                            <div className="widget-sidebar-item-wrap rld-single-select">                            
                                <Input
                                    name="order"
                                    className="select single-select" 
                                    defaultValue={this.props.order}
                                    onChange={(e) => this.props.handleOnChangeOrder(e)}
                                    type="select">
                                    <option value="0">Ordenar por</option>                   
                                    <option value="menor-preco">Menor preço</option>
                                    <option value="maior-preco">Maior preço</option>
                                    <option value="menor-area">Menor área</option>
                                    <option value="maior-area">Maior área</option>
                                    <option value="destaque">Destaques</option>
                                </Input>
                            </div>
                        </div>
                        <div className="col-right">
                            <div className="button-filters-box">                            
                                <Switch
                                    id="checkedMap"
                                    checked={this.state.checkedMap}
                                    onChange={this.handleChangeMap}
                                    color="secondary"
                                    name="checkedMap"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <label htmlFor="checkedMap">Ver no mapa</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}