import React from 'react';
import { Link } from 'react-router-dom';

import listItems from './listItems';

import './styles.css';

const Menu = () => {
    return (
        <div id="menu-box">

            <span tabIndex='0'>
                <i className="fa fa-bars"/> &nbsp;Menu
            </span>            
            <ul>
                <li><i className="fa fa-times-circle-o"></i></li>
                <li>
                    <a href="/aluguel/todos-os-tipos/todos-os-bairros-e-cidades">
                        <i className='fa fa-key'></i>
                        <span>Imóveis para alugar</span>
                    </a>
                </li>
                <li>
                    <a href="/venda/todos-os-tipos/todos-os-bairros-e-cidades">
                        <i className='fa fa-home'></i>
                        <span>Imóveis à venda</span>
                    </a>
                </li>
                {listItems.map((item, index) => (
                    <li key={index}>
                        <Link to={{pathname:item.to}} target={item.target} rel="noopener noreferrer"> 
                            <i className={item.icon}></i>
                            <span>{item.label}</span>
                        </Link>
                    </li>
                ))}                                                        
            </ul>
        </div>
    );
}

export default Menu;
