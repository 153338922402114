import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Link as Botao} from 'react-scroll';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import FormPropertyRegistration from './FormPropertyRegistration';
import Headline from '../../../components/globals/HeadLine';
import CardServices from "../../../components/globals/Cards/Servicos";
import Card from "../../../components/globals/Cards/Vantagens";

import juridico from '../../../assets/images/icons/juridico.svg';
import anuncio from '../../../assets/images/icons/anuncio.svg';
import adm from '../../../assets/images/icons/adm.svg';

import './styles.css';

const initialListServices = [
    {
        label: "Visibilidade",
        text: "Recebemos vários acessos mensais de clientes interessados nos imóveis e somos referência na práticas de divulgação online."
    },
    {
        label: "Autoridade",
        text: "Contamos com a parceria com os melhores profissionais para te ajudar a fechar o seu negócio."
    },
    {
        label: "Cliente ideal",
        text: "O seu imóvel é exibido para o cliente mais interessado. Identificamos o perfil de nossos clientes e apresentamos as melhores oportunidades para as suas necessidades."
    }
]

export default class Content extends Component {

    render() {
        return (
            <main id='propertyRegistration-main'>
                <div className="topo">                    
                    <div className="container">
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to='/'>Torres de Melo Neto</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Cadastre seu imóvel
                        </BreadcrumbItem>

                    </Breadcrumb>
                    </div>
                </div>
                <div className="container content">
                    <div className="row">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="section-title">
                                <h1 className="title">Cadastre seu imóvel na Torres de Melo Neto</h1>
                                <p>Somos uma das maiores referências do setor imobiliário da cidade e seu imóvel será 
                                    negociado por especialistas e clientes realmente interessados
                                </p>
                            </div>

                            <CardServices listServices={initialListServices} />
                            
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <FormPropertyRegistration />
                        </div>
                    </div>
                </div>

                <div className="block-cards">
                    <div className="container">
                        <Headline label="Por que anunciar aqui?" />

                        <div className="content-cards">
                            <Card icon={juridico}>
                                Assessoria Jurídica: Oferecemos a segurança que você precisa em suas transações imobiliárias
                            </Card>
                            <Card icon={anuncio}>
                                Anúncio gratuito: Anunciamos o seu imóvel nos maiores portais do Brasil e no nosso site gratuitamente
                            </Card>
                            <Card icon={adm}>
                                Administração: Pode ficar tranquilo! Administramos o seu imóvel com todo o zelo e a experiência de quem está há mais de 30 anos no mercado
                            </Card>
                        </div>
                    </div>
                </div>

                <div className="block-instruction">
                    <div className="container">
                        <Headline label="Sou proprietário, como faço para divulgar?" />

                        <p>
                            Preencha o formulário do início desta página. E pronto.
                            Entraremos em contato para agendar uma visita e seguir com os demais detalhes da divulgação.
                        </p>

                        <Botao 
                            to="block-form-registration" 
                            smooth={true} 
                            duration={800}
                            offset={-130}
                            className="btn btn-primary"                            
                        >
                            Cadastrar meu imóvel
                        </Botao>
                    </div>
                </div>
            </main>
        );
    }        
}