import React, { Fragment } from 'react';
import Card from "../../../components/globals/Cards/Vantagens";
import Botao from "../../../components/globals/Botao";

import assinatura from '../../../assets/images/icons/assinatura-digital.svg'
import voucher from '../../../assets/images/icons/voucher.svg'
import tour from '../../../assets/images/icons/tour.svg'

const Inquilinos = () => {
    return (
        <Fragment>       
            <Card icon={assinatura}>
                Assinatura eletrônica: Descomplicamos e facilitamos tudo para você. Assine seu contrato em qualquer lugar e sem custos
            </Card>
            <Card icon={voucher}>
                Pet voucher: Cuidamos do seu bichinho enquanto você faz a mudança. No aluguel do seu imóvel você ganha um pet voucher com uma diária na clínica HotelZoo
            </Card>
            <Card icon={tour}>
                Tour virtual: Otimize seu tempo visitando o imóvel através do nosso tour virtual
            </Card>

            <Botao to='/aluguel/todos-os-tipos/todos-os-bairros-e-cidades' text="Encontre seu aqui" />
        </Fragment>
    );
}

export default Inquilinos;