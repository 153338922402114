import React, { Component } from 'react';
import { Input } from 'reactstrap';
import LoadingMask from "react-loadingmask";
import NumberFormat from "react-number-format";
import SelectOptions from '../SelectOptions';
import fields from './../../../fields';
import Utils from './../../../../../helpers/Utils';
import Services from "../../../Services";

import './styles.css';

export default class FormLocation extends Component {
    state = {
        ...fields,
        openSearchPlus: false,
        loadingContent: true
    }

    constructor(props) {
        super(props);
        this._services = new Services();        
    }

    componentDidMount() {
        this._services.getTiposImoveisOptions('aluguel')
            .then((tipos) => {this.setState({tipos: tipos.options})}
        );

        this._services.getbuscaEndereco('aluguel','')
            .then(endereco => this.setState({
                bairros: endereco.resultado.bairros,
                cidades: endereco.resultado.cidades,
                loadingContent: false, 
            })
        );
    }    

    openSearchPlus = () => {
        this.setState({openSearchPlus: !this.state.openSearchPlus });        
        const element = document.querySelector('#busca-avancada');

        if (!this.state.openSearchPlus) {
            element.classList.add('open-search-plus');
        } else {
            element.classList.remove('open-search-plus');
        }
    };

    handleSelect = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : ''),
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value : '')
            }
        });
    };

    handleOnChangeModalidade = (e) => {
        this.setState({
            [e.target.name]: (e.target.value !== null ? e.target.value : ''),
            loading:true
        });

        this._services.getTiposImoveisOptions(e.target.value)
            .then(tipos => this.setState({tipos: tipos.options}));

        this._services.getbuscaEndereco(e.target.value,'')
            .then(endereco => this.setState({
                bairros: endereco.resultado.bairros,
                cidades: endereco.resultado.cidades,
                logradouros: endereco.resultado.logradouros,
                loading:false
            }));

    };

    handleOnChange = (e) => {
        this.setState({
            filtros: {
                ...this.state.filtros,
                [e.target.name]: (e.target.value !== null ? e.target.value.replace('.','').replace('.','').replace(',00','') : '')
            }
        });
    };

    handleEnter = (e) => {
        if (e.key === 'Enter') {
            this.setState({              
                offset: 0
            }, () => {
                this.renderRedirect();
            });
        }
    };

    renderRedirect = () => {
        let tipos = this.state.listTipos.toString();
        let endereco = this.state.listEnderecos.toString();    
        this.props.history.push('/'+this.state.modalidade.toLowerCase()+'/'+Utils.transliterate(tipos.replace(/,/g,'+'))+'/'+endereco.replace(/,/g,'+')+this.adicionarFiltros());
    };

    adicionarFiltros() {
        let filtros = this.state.filtros;
        let resultado = "";
        
        for (let i in filtros) {
            if (filtros[i] !== ''){
                if (i !== 'order'){ resultado += filtros[i] + '-' + i + '+';}
            }
        }
        return '/'+ resultado.substr(0,(resultado.length - 1));
    }

    getTipos = (list) => {
        this.setState({
            listTipos: list.length > 0 && list.indexOf('todos-os-tipos') === -1 ?
            list : ['todos-os-tipos']
        })
    }

    getEndereco = (list) => {        
        this.setState({
            listEnderecos: list.length > 0 && list.indexOf('todos-os-bairros-e-cidades') === -1 ?
            list : ['todos-os-bairros-e-cidades']});            
    }

    render(){
        const todosTipos = [
            { category: '', name: 'Todos os tipos', value: 'todos-os-tipos' }
        ];

        const todosBairrosCidades = [
            { category: 'Todos', name: 'Todos os bairros e cidades', value: 'todos-os-bairros-e-cidades' }
        ];

        const listBairros =
            this.state.bairros.map((bairro) => (
                {category: 'Bairros', name: Utils.ucWords(bairro.text), value: Utils.transliterate(bairro.id) }
            ));

        const listCidades =
            this.state.cidades.map((cidade) => (
                {category: 'Cidades', name: Utils.ucWords(cidade.text), value: Utils.transliterate(cidade.id) }
            ));

        return (           
            <LoadingMask loading={this.state.loadingContent} >
            <form className="form-box">
                <div className="busca">              
                    <div className="select-box column-2">
                        <Input                             
                            type="select" 
                            name="modalidade"                            
                            onChange={(e) => this.handleOnChangeModalidade(e)}
                            defaultValue={this.state.modalidade}>
                            <option value="aluguel">Aluguel</option>
                            <option value="venda">Comprar</option>
                        </Input>
                    </div>

                    <div className="input-box column-3">                        
                        <SelectOptions 
                            name='Tipos'
                            valueTodos='todos-os-tipos'
                            selecteds={this.state.listTipos}
                            options={todosTipos.concat(this.state.tipos)}
                            getOptions={this.getTipos}
                        />
                    </div>

                    <div className="input-box column-6">
                        <SelectOptions 
                            name='Enderecos'
                            valueTodos='todos-os-bairros-e-cidades'
                            selecteds={this.state.listEnderecos}
                            options={todosBairrosCidades.concat(listCidades).concat(listBairros)}
                            getOptions={this.getEndereco}
                        />
                    </div>
                    
                    <div className="botao-box">
                        <button type="button" className="btn btn-primary" onClick={e => this.renderRedirect(e)}>Buscar</button>
                    </div>
                </div>

                <div className="search-plus-box" onClick={this.openSearchPlus}>
                    <i className={`fa fa-search-${this.state.openSearchPlus ? 'minus': 'plus' }`} /> Mais filtros                                                
                </div>
                

                <div id="busca-avancada" className="busca-avancada">
                    <div className="select-box column-3">
                        <Input type="select" name="quartos" onChange={e => this.handleSelect(e)}>
                            <option value={''}>Quartos</option>
                            <option value={1}>1+ quartos</option>
                            <option value={2}>2+ quartos</option>
                            <option value={3}>3+ quartos</option>
                            <option value={4}>4+ quartos</option>
                            <option value={5}>5+ quartos</option>
                        </Input>
                    </div>

                    <div className="select-box column-3">
                        <Input type="select" name="suites" onChange={e => this.handleSelect(e)}>
                            <option value={''}>Suítes</option>
                            <option value={1}>1+ suítes</option>
                            <option value={2}>2+ suítes</option>
                            <option value={3}>3+ suítes</option>
                            <option value={4}>4+ suítes</option>
                            <option value={5}>5+ suítes</option>
                        </Input>
                    </div>

                    <div className="select-box column-3">
                        <Input type="select" name="banheiros" onChange={e => this.handleSelect(e)}>
                            <option value={''}>Banheiros</option>
                            <option value={1}>1+ banheiros</option>
                            <option value={2}>2+ banheiros</option>
                            <option value={3}>3+ banheiros</option>
                            <option value={4}>4+ banheiros</option>
                            <option value={5}>5+ banheiros</option>
                        </Input>
                    </div>

                    <div className="select-box column-3">
                        <Input type="select" name="vagas" onChange={e => this.handleSelect(e)}>
                            <option value={''}>Vagas</option>
                            <option value={1}>1+ vagas</option>
                            <option value={2}>2+ vagas</option>
                            <option value={3}>3+ vagas</option>
                            <option value={4}>4+ vagas</option>
                            <option value={5}>5+ vagas</option>
                        </Input>
                    </div>

                    <div className="input-box column-3">                        
                        <NumberFormat
                            name="valorInicial"
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={this.state.valorInicial}
                            onKeyPress={e => this.handleEnter(e)}
                            onChange={e => this.handleOnChange(e)}
                            className="form-control"
                            id="valorInicial"
                            placeholder="Valor inicial R$"
                        />
                    </div>
                    <div className="input-box column-3">                        
                        <NumberFormat
                            name="valorFinal"
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={this.state.valorFinal}
                            onKeyPress={e => this.handleEnter(e)}
                            onChange={e => this.handleOnChange(e)}
                            className="form-control"
                            id="valorFinal"
                            placeholder="Valor final R$"
                        />
                    </div>

                    <div className="input-box column-3">
                        <NumberFormat
                            name="areaInicial"
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={this.state.areaInicial}
                            onKeyPress={e => this.handleEnter(e)}
                            onChange={e => this.handleOnChange(e)}
                            className="form-control"
                            id="areaInicial"
                            placeholder="Área inicial m²"
                        />
                    </div>
                    <div className="input-box column-3">                        
                        <NumberFormat
                            name="areaFinal"
                            thousandSeparator='.'
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            value={this.state.areaFinal}
                            onKeyPress={e => this.handleEnter(e)}
                            onChange={e => this.handleOnChange(e)}
                            className="form-control"
                            id="areaFinal"
                            placeholder="Área final m²"
                        />
                    </div>

                </div>
                
            </form>
            </LoadingMask>            
        );
    }
}