import React, { Fragment } from 'react';
import Card from "../../../components/globals/Cards/Vantagens";
import Botao from "../../../components/globals/Botao";

import juridico from '../../../assets/images/icons/juridico.svg';
import anuncio from '../../../assets/images/icons/anuncio.svg';
import adm from '../../../assets/images/icons/adm.svg';

const Proprietarios = () => {
    return (
        <Fragment>       
            <Card icon={juridico}>
                Assessoria Jurídica: Oferecemos a segurança que você precisa em suas transações imobiliárias
            </Card>
            <Card icon={anuncio}>
                Anúncio gratuito: Anunciamos o seu imóvel nos maiores portais do Brasil e no nosso site gratuitamente
            </Card>
            <Card icon={adm}>
                Administração: Pode ficar tranquilo! Administramos o seu imóvel com todo o zelo e a experiência de quem está há mais de 30 anos no mercado
            </Card>

            <Botao to='/cadastre-seu-imovel' text="Cadastre seu imóvel" />

        </Fragment>
    );
}

export default Proprietarios;