import BaseServices from '../../helpers/BaseServices';
import { API_VENDAS, API_CADIMO5 } from "./constants";
import RequestUtils from '../../config/requestUtils';

export default class Services extends BaseServices {
    constructor() {
        super(API_VENDAS, API_CADIMO5);
    };

    busca = (modalidade, tipo, endereco, filtros = [], page, order = 'menor-valor', limit = 10, offset = 0, caracSelecionados = [], caracSelecionadosCondominio = []) => {

        let currentPage = (page ? page : 1);
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('busca', modalidade);

        url += `${method}?limit=${limit}&offset=${offset}&page=${currentPage}&tipo=${tipo.replace('-', ' ')}&endereco=${endereco}`;

        if (filtros) {
            for (let i in filtros) {
                if (filtros[i] === '' || filtros[i] === null || filtros[i] === undefined) continue;
                url += `&${i}=${filtros[i]}`;
            }
        }

        if (caracSelecionados.length > 0) {
            let list = caracSelecionados.map(item => [
                item.value
            ]);
            url += `&caracteristicas=${list}`;
        }
        
        if (caracSelecionadosCondominio.length > 0) {   
            let list = caracSelecionadosCondominio.map(item => [
                item.value
            ]);
            url += `&caracsCondominio=${list}`;
        }

        return fetch(url, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    };

    getTiposImoveisOptions = (modalidade) => {
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('tipos', modalidade);

        return fetch(`${url}${method}`, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }

    getbuscaEndereco = (modalidade, palavra) => {
        let url = RequestUtils.uriResolver(modalidade);
        let method = RequestUtils.methodResolver('buscaEndereco', modalidade);

        return fetch(`${url}${method}${palavra}`, BaseServices._getRequestInfo(modalidade))
            .then(response => {
                if (!response.ok) {
                    throw response.json();
                }

                return response.json();
            });
    }
}
