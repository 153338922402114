import React, { Fragment } from 'react';

const CardServices = ({listServices}) => {
    return (
        <Fragment>
        {listServices.map((item, index) => (
            <div key={ index } className="accordion" id={`accordion${index}`}>
                <div className="single-accordion card">
                    <div className="card-header" id={`item-${index}`}>
                    <h2 className="mb-0">
                        <button 
                            className={`btn-link ${index === 0 ? '': 'collapsed'}`} 
                            type="button" 
                            data-toggle="collapse" 
                            data-target={`#collapse${index}`} 
                            aria-expanded="true" 
                            aria-controls={`collapse${index}`}>
                            { item.label }
                        </button>
                    </h2>
                    </div>
                    <div id={`collapse${index}`} className={`collapse ${index === 0 ? 'show': ''}`}  aria-labelledby={`item-${index}`} data-parent={`#accordion${index}`}>
                        <div className="card-body">
                            { item.text }
                        </div>
                    </div>
                </div>
            </div>
        ))}
        </Fragment>
    );
}
export default CardServices;